<template>
  <div class="product-wrap">
    <div class="productTop">
      <div class="item1">
        <div class="item1Img">
          <!-- ../../../assets/shoppingCart/product.png -->
          <!-- <img :src="item.materialGroupId+'/'+item.productId+'/180-180/'+item.picture" alt /> -->
          <span>
               <h-img :imgUrl="item.img" :errorImg="item.defaultImg" :isUseBasePath="true"></h-img>
          </span>
        </div>
        <div class="item1Val">
          <div class="item1Title">
            <span v-if="item.freeDelivery == '1'">免运费</span>
<!--            <span>免运费</span>-->
            <span  v-if="item.productLabel && item.productLabel.indexOf('15691143849')!==-1" class="bright">璀璨</span>
            <p v-if="item.baseProductInfo.zzprdmodel">{{item.baseProductInfo.zzprdmodel}}</p>
          </div>
          <p class="first">
            <span style="margin-right:5px;">品牌：{{item.baseProductInfo.z_brand}}</span>
            <span class="right" v-if="item.baseProductInfo.z_color">颜色：{{item.baseProductInfo.z_color}}</span>
          </p>
          <p v-if="item.loadVolume">
            体积：{{$util.getFloat(item.loadVolume,3)}}m³
            <span class="right">批次：{{item.batchId_name}}</span>
          </p>
        </div>
      </div>
      <div class="item2">
        <div class="item2Title">
          <i>￥{{$util.getFloat(item.billPrice,2)}}</i>
<!--          <span style="margin-left:5px;" v-if="item.standardPrice">￥{{$util.getFloat(item.billPrice,2)}}</span>-->
          <!-- {{$util.getFloat(item.billPrice,2)}}元 -->
        </div>
        <div class="item2Item">
          <!-- <p>固定折扣：{{item.fixedDiscount}}%</p> -->
          <p>定价组：{{item.pricingGroup}}</p>
<!--          <p>补差类型：{{item.makeUpTypeName}}</p>-->
          <!-- <p>单台返利30元</p> -->
        </div>
        <div class="item2Item">
          <div>竣工后补差金额(元/套)：￥{{ item.diffentPrice?Number(item.diffentPrice).toFixed(2):'0.00' }} </div>
        </div>
        <div class="rebate-div" v-if="item.isShowRebate && item.batchId_name == 'H'">最高可享台返{{Number(item.returnAmount || 0).toFixed(2)}}元，政策后提货价{{Number(item.billPrice - (item.returnAmount || 0)).toFixed(2)}}元，限量{{item.upperLimit || 999}}台</div>
      </div>
      <div class="item3">
        <div class="item3Title">×{{item.quantity}}</div>
        <div class="item3Item">
          <div class="item3Items">
          </div>
        </div>
      </div>
      <div class="item4" v-show="trans != 502004&&trans != 502008 ">
        <div class="item4Title">小计：￥{{$util.getFloat(item.billPrice*item.quantity,2)}}</div>
      </div>
      <div class="item4" v-show="(trans == 502004||trans == 502008) && !haveDeliveryFee">
        <div class="item4Title">小计：￥{{$util.getFloat(item.billPrice*item.quantity,2)}}</div>
        <div class="three">
          零售价格：￥
          <span  :style="{color:Number(item.salePrice)>0?'#262626':'#777'} " v-if="!item.idEdit">{{Number(item.salePrice) && Number(item.salePrice)>0?item.salePrice:'请输入'}}</span>
          <a-input type="text" oninput="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                   v-if="item.idEdit" @blur="blurPrice(item)" @change="changePrice(item,$event)" style="width: 100px" v-model="item.salePrice" />
          <span class="edit" @click="edit(item)">{{item.idEdit?'完成':'编辑'}}</span>
        </div>
      </div>
      <div class="item4" v-show="(trans== 502004||trans== 502008) && haveDeliveryFee">
        <div class="item4Title">小计：</div>
        <p class="first">
          <span style="margin-right:5px;">货款：￥{{$util.getFloat(item.billPrice*item.quantity,2)}}</span>
        </p>
        <p class="two">
          <span style="margin-right:5px;">服务费：￥{{item.serviceMoney?Number(item.serviceMoney).toFixed(2):'0.00'}}</span>
        </p>
        <div class="three">
          零售价格：￥
          <span  :style="{color:Number(item.salePrice)>0?'#262626':'#777'} " v-if="!item.idEdit">{{Number(item.salePrice) && Number(item.salePrice)>0?item.salePrice:'请输入'}}</span>
          <a-input oninput="this.value=this.value.match(/\d+\.?\d{0,2}/)" type="text"  v-if="item.idEdit" @blur="blurPrice(item)" @change="changePrice(item,$event)" style="width: 100px" v-model="item.salePrice" />
          <span class="edit" @click="edit(item)">{{item.idEdit?'完成':'编辑'}}</span>
        </div>
      </div>


    </div>

    <div>

    </div>
  </div>
</template>

<script>
export default {
  name: "ProductItem",
  props: {
    item: {
      required: true
    },
    settlementItem: {
      required: false
    },
    proHide: {
      required: true
    },
    trans:{
      required: true
    },
    haveDeliveryFee:{
      required: true
    },
    // // 特购专区
    // settlement: {
    //   required: true,
    //   default: false
    // },
  },
  data() {
    return {
      makeShure: true,
      idEdit:false
    };
  },
  watch:{
    trans: {
      handler: function(val, oldVal) {
      },
      // 深度观察监听
      deep: true
    },
    item: {
      handler: function(val, oldVal) {},
      // 深度观察监听
      deep: true
    },
    haveDeliveryFee: {
      handler: function(val, oldVal) {
      },
      // 深度观察监听
      deep: true
    },
  },

  methods: {
    // changeOpened(item) {
    //   this.$set(item, "opened", !item.opened);
    //   // item.opened = !item.opened
    // },
    edit(item) {
      item.idEdit = !item.idEdit
      this.$forceUpdate()
    },
    changePrice(item,e){
      this.$emit("changePrice", item);
    },
    blurPrice(item,e){
      item.salePrice=item.salePrice.replace(/[^\-?\d.]/g,'')
      item.salePrice= Number(item.salePrice).toFixed(2)
      if (item.type == 1 && item.salePrice) {
        if (Number(item.salePrice) < item.min || Number(item.salePrice) > item.max) {
          if (item.haveRetailL) {
            this.$message.warning(`请在默认建议零售价的${item.bei}倍范围内录入价格`);
          } else {
            this.$message.warning(`您录入的价格不符合产品公司规定，请按要求录入。`);
          }
          item.salePrice = item.retailPrice;
          this.$forceUpdate()
          return;
        }
      }
      this.$emit("changePrice", item);
    },
    // // 点击取消按钮
    cancleClick(data) {
      this.$emit("cancleClick", data);
    },
    // 特购页面点击是否需要特购优惠
    handleChange(data) {
      this.makeShure = !this.makeShure;
      this.$emit("settleChose", data);
    },
    // 点击购物车
    carClick() {
      this.$emit("carClick", "");
    }
  }
};
</script>

<!--<style scoped lang="less">-->
<!--.product-wrap {-->
<!--  width: 1140px;-->
<!--  height: 144px;-->
<!--  padding: 22px 24px ;-->
<!--  background: #F7F7F7;-->
<!--  // margin: 20px auto 0;-->
<!--  margin-top: 16px;-->
<!--  box-sizing: border-box;-->
<!--  text-align: left;-->
<!--  border-radius: 8px;-->
<!--  .productTop {-->
<!--    display: flex;-->
<!--    justify-content: flex-start;-->
<!--    .item1 {-->
<!--      width: 400px;-->
<!--      height: 100px;-->
<!--      display: flex;-->
<!--      justify-content: flex-start;-->
<!--      .item1Img {-->
<!--        margin-top: -2px;-->
<!--        width: 100px;-->
<!--        height: 100px;-->
<!--        background: #FFFFFF;-->
<!--        border-radius: 4px;-->
<!--        margin-right: 16px;-->
<!--        display: flex;-->
<!--        align-items: center;-->
<!--        justify-content: center;-->
<!--        span {-->
<!--          width: 80px;-->
<!--          height: 80px;-->
<!--          img {-->
<!--           width: 100%;-->
<!--            height: 100%;-->
<!--          }-->
<!--        }-->

<!--      }-->
<!--      .item1Val {-->
<!--        text-align: left;-->
<!--        padding-top: 5px;-->
<!--        box-sizing: border-box;-->
<!--        .item1Title {-->
<!--          display: flex;-->
<!--          justify-items: flex-start;-->
<!--          align-items: center;-->
<!--          span{-->
<!--              width: 45px;-->
<!--              height: 16px;-->
<!--              font-size: 12px;-->
<!--              color: #FFFFFF;-->
<!--              font-weight: 400;-->
<!--              line-height: 16px;-->
<!--              background: linear-gradient(90deg, #FF7C45 0%, #F63737 100%);-->
<!--              border-radius: 2px;-->
<!--              margin-right: 6px;-->
<!--              display: flex;-->
<!--              align-items: center;-->
<!--              justify-content: center;-->
<!--          }-->
<!--          p {-->
<!--            // width: 240px;-->
<!--            // overflow: hidden;-->
<!--            // text-overflow: ellipsis;-->
<!--            // white-space: nowrap;-->
<!--            font-family: PingFangSC-Medium;-->
<!--            font-size: 14px;-->
<!--            color: #262626;-->
<!--            line-height: 20px;-->
<!--            margin-top: 0;-->
<!--            //font-weight: 600;-->
<!--          }-->
<!--          .buyCar {-->
<!--            width: 28px;-->
<!--            height: 28px;-->
<!--            border-radius: 14px;-->
<!--            background: #ecf8f8;-->
<!--            text-align: center;-->
<!--            cursor: pointer;-->
<!--            img {-->
<!--              width: 16px;-->
<!--              height: 16px;-->
<!--              margin-top: 6px;-->
<!--            }-->
<!--          }-->
<!--        }-->
<!--        p {-->
<!--          -->
<!--          font-size: 12px;-->
<!--          color: #777;-->
<!--          line-height: 16px;-->
<!--          -->
<!--          .right{-->
<!--            display:inline-block;-->
<!--            margin-left: 15px;-->
<!--          }-->
<!--        }-->
<!--        .first{-->
<!--          margin-top: 15px;-->
<!--          margin-bottom: 8px;-->
<!--        }-->
<!--               -->
<!--        -->
<!--      }-->
<!--    }-->
<!--    .item2 {-->
<!--      width: 240px;-->
<!--      margin-left: 15px;-->
<!--      .item2Title {-->
<!--        width: 185px;-->
<!--        overflow: hidden;-->
<!--        text-overflow: ellipsis;-->
<!--        flex-wrap: nowrap;-->
<!--        font-size: 14px;-->
<!--        color: #262626;-->
<!--        line-height: 20px;-->
<!--        padding-top: 5px;-->
<!--        box-sizing: border-box;-->
<!--        //font-weight: 600;-->
<!--        span {-->
<!--          text-decoration: line-through;-->
<!--          font-size: 12px;-->
<!--          color: #777;-->
<!--        }-->
<!--      }-->
<!--      .item2Item {-->
<!--        // width: 195px;-->
<!--        font-size: 12px;-->
<!--        color: #777;-->
<!--        line-height: 16px;-->
<!--        display: flex;-->
<!--        justify-content: flex-start;-->
<!--        flex-wrap: wrap;-->
<!--        p {-->
<!--          margin-top: 15px;-->
<!--        }-->
<!--        p:first-child {-->
<!--          margin-right: 16px;-->
<!--        }-->
<!--        div{-->
<!--          margin-right: 16px;-->
<!--          margin-top: 8px;-->
<!--        }-->
<!--      }-->
<!--    }-->
<!--    .item3 {-->
<!--      width: 210px;-->
<!--      margin-left: 24px;-->
<!--      .item3Title {-->
<!--        padding-top: 5px;-->
<!--        box-sizing: border-box;-->
<!--        font-family: PingFangSC-Medium;-->
<!--        font-size: 14px;-->
<!--        color: #262626;-->
<!--        line-height: 20px;-->
<!--        //font-weight: 600;-->
<!--        margin-bottom: 17px;-->
<!--      }-->
<!--      .deliver{-->
<!--        width: 100px;-->
<!--          height: 12px;-->
<!--          font-size: 12px;-->
<!--          font-weight: 400;-->
<!--          color: #777777;-->
<!--          line-height: 12px;-->
<!--      }-->
<!--      .item3Item {-->
<!--        -->
<!--        font-size: 12px;-->
<!--        color: #777;-->
<!--        line-height: 16px;-->

<!--        p {-->
<!--          margin-top: 8px;-->
<!--        }-->
<!--        .item3Items {-->
<!--          display: flex;-->
<!--          justify-content: flex-start;-->
<!--          flex-wrap: wrap;-->

<!--          p {-->
<!--            margin-right: 10px;-->
<!--          }-->
<!--        }-->
<!--      }-->
<!--    }-->
<!--    .item4 {-->
<!--      width: 250px;-->
<!--      padding-top: 5px;-->
<!--      box-sizing: border-box;-->
<!--      .item4Title {-->
<!--        font-family: PingFangSC-Medium;-->
<!--        font-size: 14px;-->
<!--        color: #262626;-->
<!--        line-height: 20px;-->
<!--        //font-weight: 600;-->
<!--      }-->
<!--      .purchase {-->
<!--        font-family: PingFangSC-Medium;-->
<!--        font-size: 12px;-->
<!--        color: #777777;-->
<!--        line-height: 16px;-->
<!--        cursor: pointer;-->
<!--        margin-top: 8px;-->
<!--      }-->
<!--      .purchase:hover {-->
<!--        color: #00aaa6;-->
<!--      }-->
<!--      p {-->
<!--        font-size: 12px;-->
<!--        color: #262626;-->
<!--        line-height: 16px;-->
<!--        .right{-->
<!--          display:inline-block;-->
<!--          margin-left: 15px;-->
<!--        }-->
<!--      }-->
<!--      .first{-->
<!--        margin-top: 12px;-->
<!--        margin-bottom: 7px;-->
<!--      }-->
<!--      .two{-->
<!--        //margin-top: 7px;-->
<!--      }-->
<!--      .three{-->
<!--        margin-top: 6px;-->
<!--        font-size:12px;-->
<!--        .edit {-->
<!--          color: #1890FF;-->
<!--          cursor: pointer;-->
<!--          margin-left: 16px;-->
<!--        }-->

<!--      }-->
<!--    }-->
<!--  }-->

<!--  .line {-->
<!--    width: 1092px;-->
<!--    height: 1px;-->
<!--    // border: 1px solid #eeeeee;-->
<!--    background: #eee;-->
<!--    margin-top: 22px;-->
<!--  }-->
<!--  .settlement {-->
<!--    width: 100%;-->
<!--    display: flex;-->
<!--    justify-content: flex-start;-->
<!--    align-items: center;-->
<!--    .settleTitle {-->
<!--      -->
<!--      font-size: 14px;-->
<!--      color: #777777;-->
<!--      margin-left: 24px;-->
<!--      margin-bottom: 4px;-->
<!--      margin-top: 16px;-->
<!--    }-->
<!--    .settlementVal {-->
<!--      width: 800px;-->
<!--      display: flex;-->
<!--      justify-content: flex-start;-->
<!--      flex-wrap: wrap;-->
<!--      margin-left: 24px;-->
<!--      p {-->
<!--        width: 120px;-->
<!--        text-align: left;-->
<!--        -->
<!--        font-size: 12px;-->
<!--        color: #777;-->
<!--        margin-right: 50px;-->
<!--        margin-top: 8px;-->
<!--      }-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</style>-->
<style scoped lang="less">
.product-wrap {
  width: 1140px;
  //height: 144px;
  padding: 22px 24px ;
  background: #F7F7F7;
  // margin: 20px auto 0;
  margin-top: 16px;
  box-sizing: border-box;
  text-align: left;
  border-radius: 8px;
  .productTop {
    display: flex;
    justify-content: flex-start;
    .item1 {
      width: 400px;
      height: 100px;
      display: flex;
      justify-content: flex-start;
      .item1Img {
        margin-top: -2px;
        width: 100px;
        height: 100px;
        background: #FFFFFF;
        border-radius: 4px;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          width: 80px;
          height: 80px;
          img {
            width: 100%;
            height: 100%;
          }
        }

      }
      .item1Val {
        text-align: left;
        padding-top: 5px;
        box-sizing: border-box;
        .item1Title {
          display: flex;
          justify-items: flex-start;
          align-items: center;
          span{
            width: 45px;
            height: 16px;
            font-size: 12px;
            color: #FFFFFF;
            font-weight: 400;
            line-height: 16px;
            background: linear-gradient(90deg, #FF7C45 0%, #F63737 100%);
            border-radius: 2px;
            margin-right: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          p {
            font-size: 14px;
            color: #262626;
            line-height: 20px;
            margin-top: 0;
            //font-weight: 600;
          }
          .bright {
            font-size: 12px;
            color: #433538;
            width: 30px;
            height: 16px;
            text-align: center;
            line-height: 16px;
            background: linear-gradient(90deg, #D49879 0%, #FEF2DC 50%, #D49879 100%);
            border-radius: 2px;
            margin-left: 4px;
            font-weight: 400;
          }
          .buyCar {
            width: 28px;
            height: 28px;
            border-radius: 14px;
            background: #ecf8f8;
            text-align: center;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
              margin-top: 6px;
            }
          }
        }
        p {

          font-size: 12px;
          color: #777;
          line-height: 16px;

          .right{
            display:inline-block;
            margin-left: 15px;
          }
        }
        .first{
          margin-top: 12px;
          margin-bottom: 7px;
        }


      }
    }
    .item2 {
      width: 220px;
      margin-left: 15px;
      position: relative;
      .item2Title {
        width: 185px;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-wrap: nowrap;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
        padding-top: 5px;
        box-sizing: border-box;
        //font-weight: 600;
        span {
          text-decoration: line-through;
          font-size: 12px;
          color: #777;
        }
      }
      .item2Item {
        // width: 195px;
        font-size: 12px;
        color: #777;
        line-height: 16px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        p {
          margin-top: 15px;
        }
        p:first-child {
          margin-right: 16px;
        }
        div{
          margin-right: 16px;
          margin-top: 8px;
        }
      }
      .rebate-div{
        height: 16px;
        line-height: 16px;
        color: #FC5260;
        font-size: 12px;
        position: absolute;
        top: 88px;
        left: 0;
        width: 400px;
      }
    }
    .item3 {
      width: 200px;
      margin-left: 24px;
      .item3Title {
        padding-top: 5px;
        box-sizing: border-box;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
        //font-weight: 600;
        margin-bottom: 17px;
      }
      .deliver{
        width: 100px;
        height: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #777777;
        line-height: 12px;
      }
      .item3Item {

        font-size: 12px;
        color: #777;
        line-height: 16px;

        p {
          margin-top: 8px;
        }
        .item3Items {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          p {
            margin-right: 10px;
          }
        }
      }
    }
    .item4 {
      width: 250px;
      padding-top: 5px;
      box-sizing: border-box;
      .item4Title {

        font-size: 14px;
        color: #262626;
        line-height: 20px;
        //font-weight: 600;
      }
      .purchase {
        font-size: 12px;
        color: #777777;
        line-height: 16px;
        cursor: pointer;
        margin-top: 8px;
      }
      .purchase:hover {
        color: #00aaa6;
      }
      p {
        font-size: 12px;
        color: #262626;
        line-height: 16px;
        .right{
          display:inline-block;
          margin-left: 15px;
        }
      }
      .first{
        margin-top: 12px;
        margin-bottom: 7px;
      }
      .two{
        //margin-top: 7px;
      }
      .three{
        margin-top: 6px;
        font-size:12px;
        .edit {
          color: #1890FF;
          cursor: pointer;
          margin-left: 16px;
        }

      }
    }
  }

  .line {
    width: 1092px;
    height: 1px;
    // border: 1px solid #eeeeee;
    background: #eee;
    margin-top: 22px;
  }
  .settlement {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 1092px;
    background: #ECF8F8;
    border-radius: 5px;
    border: 1px solid rgba(0,170,166,0.24);
    padding:8px 16px;
    margin-top:12px;
    .settleTitle {
      font-size: 14px;
      color: #777777;
      //margin-left: 24px;
      //margin-bottom: 4px;
      //margin-top: 16px;
    }
    .settlementVal {
      width: 800px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      //margin-left: 24px;
      p {
        //width: 120px;
        text-align: left;
        font-size: 12px;
        color: #777;
        margin-right: 50px;
        //margin-top: 8px;
      }
    }
  }
}
</style>