/*
 * @Author: HIDESK\yanbenrong.ex ybr15650585591@163.com
 * @Date: 2023-12-13 10:30:14
 * @LastEditors: mayu.ex 15163353598@163.com
 * @LastEditTime: 2024-04-17 14:19:46
 * @FilePath: \B2B\src\views\specialZoneGC\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Step from '@/components/global/Step/Step.vue'
import ProductItem from './components/ProductItem.vue'
import ModalBox from './components/Modal-box.vue'
import addUserSource from "@/views/userOperations/IntendedUserEntry/components/addUserSource";
import addUserTips from "@/views/userOperations/IntendedUserEntry/components/addUserTips";
import { checkShipTo, getAdressapi, serviceInformation } from "./../../views/settlement/api";
import {
  // orderWeek,
  // 购物车查询
  // orderCar,
  // 统仓联系人
  // orderAddress,
  // 分销商 分销地址
  showAnother,
  SalesShopInfoList1,
  // 收货地址切换带出联系人
  //。。。。
  // 获取城市
  cityChange,
  // 获取城镇 区
  townChange,
  // 获取城镇区下一级
  getQu,
  // 分销商切换 获取新分销商地址
  getDISAdd,
  // 分销商地址切换请求联系人
  getPhone,
  // 要求到货周
  // getWeek,
  // 商品列表
  //goodsList,
  // 获取商品列表库存数
  getNumber,
  // 购物车提交
  // 查看预占用额度明细
  quotaDeatail,
  // 特惠专区接口
  specialZone,
  // 工程订单提交
  engineerSubmit,
  // 充值
  saveMoney,
  // 充值记录
  saveHisetory,
  //工程列表数据
  getEngineerOrder, queryServiceTypeSelect, checkIsOneProject

} from "./api";
import {
  saveMoneyType
} from "@/common/constant";
import
moment
from 'moment';
import { focusonremarkplz } from "@/views/basicInformation/components/newMerchantaddEdit/api";
import { findList, findListLabel, saveInfo, saveInfoLabel } from "@/views/userOperations/IntendedUserEntry/api";
import { getUserList } from "@/views/userOperations/IntentionList/api";
import { checkRetailL } from "@/views/channelInput/saleNew/api";
import { findMoneyBuyBegin } from "@/views/specialZone/api";
import { ctsApi } from "@/views/channel/api";
import addressModal from "@/views/settlement/components/addressModal";

export default {
  name: "Home",
  components: {
    Step,
    ProductItem,
    ModalBox,
    addUserSource,
    addUserTips,
    addressModal,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  data() {
    return {
      visibleAddress:false,
      newTown:'',
      ifOnece:false,//是否一次性工程
      modelShow: false,
      // 查看占用明细列表
      columns: [{
          title: '销售组织',
          dataIndex: 'orgName',
          key: 'orgName',
          width:100
        },
        {
          title: '物料组',
          dataIndex: 'matklName',
          key: 'amatklNamege',
          width:100
        },
        {
          title: '订单编码',
          dataIndex: 'orderCode',
          key: 'orderCode',
          width:150
        },
        {
          title: '订单类型',
          dataIndex: 'orderType',
          key: 'orderType',
          width:100
        },
        {
          title: '订单状态',
          dataIndex: 'status',
          key: 'status',
          width:100
        },
        {
          title: '商品型号&数量',
          dataIndex: 'productAndQtys',
          key: 'productAndQtys',
          width:250,
          ellipsis: true,
        },
        {
          title: '付款状态',
          dataIndex: 'fkStatus',
          key: 'fkStatus',
          width:100
        }, {
          title: '订单金额',
          dataIndex: 'orderAmt',
          key: 'orderAmt',
          align: 'right',
          width:100
        },  {
          title: '预占用金额',
          dataIndex: 'waitBalance',
          key: 'waitBalance',
          width:120,
          align: 'right',
          scopedSlots: { customRender: "type" },
        },  {
          title: '要求到货周次',
          dataIndex: 'weekName',
          key: 'weekName',
          width:150
        },
        {
          title: '下单日期',
          dataIndex: 'createdDate',
          key: 'createdDate',
          width:150
        },
      ],
      // 查看占用明细列表
      data: [],
      bankChecked: true,
      visible: false,
      steps: ['选择商品', '确认订单信息', '提交订单'],
      currentStep: 2,
      options: [],
      optionsT: [],
      prods: [{
          id: 1
        },
        {
          id: 2
        }
      ],
      //form
      labelCol: {
        span: 4
      },
      wrapperCol: {
        span: 14
      },
      other: '',
      form: {
        name: '',
        billName: undefined,
        address: undefined,
        agency: undefined,
        askWeekend: undefined,
        distributor: undefined,
        distributorAddress: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: '',
      },
      rules: {
        name: [{
            required: true,
            message: '请输入联系人',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 5,
            message: 'Length should be 3 to 5',
            trigger: 'blur'
          }
        ],
        phone: [{
            required: true,
            message: '请输入联系方式',
            trigger: 'blur'
          },
          {
            min: 7,
            max: 11,
            message: '请输入正确的联系方式',
            trigger: 'blur'
          },
        ],
        billName: [{
          required: true,
          message: '请选择开票户头',
          trigger: 'change'
        }],
        address: [{
          required: true,
          message: '请选择收货地址',
          trigger: 'change'
        }],
        agency: [{
          required: true,
          message: '请选择海信办事处',
          trigger: 'change'
        }],
        askWeekend: [{
          required: true,
          message: '请选择周',
          trigger: 'change'
        }],
        distributor: [{
          required: true,
          message: '请选择分销商',
          trigger: 'change'
        }],
        distributorAddress: [{
          required: true,
          message: '请选择分销商地址',
          trigger: 'change'
        }],
        date1: [{
          required: true,
          message: 'Please pick a date',
          trigger: 'change'
        }],
        type: [{
          type: 'array',
          required: true,
          message: 'Please select at least one activity type',
          trigger: 'change',
        }, ],
        resource: [{
          required: true,
          message: 'Please select activity resource',
          trigger: 'change'
        }, ],
      },
      //工程单编码
      EngineerOrderCode:"",
      // 联系人
      contacts: '',
      // 联系人手机号
      phoneCon: '',
      // 开票户头账号
      choseAccounts: [],
      // 是否显示其他开票户头
      accountShow: false,
      // 开票户头
      invoice: '',
      // 开票户头id
      invoiceId: '',
       //开票信息
      show:true,//未填写内容是否展示
      //是否允许提前发货
      allowAdvance: true,
      // 用户选择截止时间
      // endTime: '',
      // 控制分销商及分销商地址是否显示
      distributors: false,
      // 经销商下拉选择框
      distr: false,
      // 经销商地址
      distrAddress: '',
      // 经销商id
      customerId:'',
      distrAdd: false,
      distrName: '',
      // 经销商地址
      orderDis: [],
      // 选择直配到分销时填写客户单号
      orderNumber: '',
      // 供应商为海信时显示供应商为物料组
      suppilers: '股份青岛-电视',
      orgCode: "", // 供应商code  2601这种
      // 配送方式选择直配到用户
      userAdderss: false,
      // 用户详细地址
      userDetailAddress: '',

      // 省市区三级联动
      provinceData: ['Zhejiang', 'Jiangsu'],
      cityData: {
        Zhejiang: ['Hangzhou', 'Ningbo', 'Wenzhou'],
        Jiangsu: ['Nanjing', 'Suzhou', 'Zhenjiang'],
      },
      cities: ['Hangzhou', 'Ningbo', 'Wenzhou'],
      secondCity: '',
      // 接收子组件选中的商品
      sonChectItme: [],
      receiveAdd: false,
      // 收货地址/统仓地址
      receive: '',
      // 收货地址id
      receiveID: '',
      shareFlag:'',
      // 收货地址类型 obj.shareFlag=='Y'?'统仓地址':'收货地址'
      houseAddress: '仓库地址',
      // 统仓联系人禁止修改
      TCInt: false,
      // 配送方式ID
      sendId: '',
      // 分销商地址选择
      distributionAddress: [],
      // 输入输入内容
      searchInt: '',
      // 省
      provices: [],
      //市
      getCity: [],
      // 城镇
      getTown: [],
      // 城镇下一级
      qu: [],
      // 省市区选择
      // proObj: {},
      // 开票户头
      billToDTOs: [],
      // 经销商地址列表
      choseDistribution: [],
      // 分销商列表
      DisList: [],
      // 经销商及经销商地址
      distributionAdd: {
        DISCODE: '',
        DISADD: ''
      },
      // 经销商切换获取地址 接口参数
      DISADDPARAMS: {
        customerId: '',
        orgId: '',
        matklId: ''
      },
      // 要求到货周
      arriveOptions: [],
      selected: '',
      GoodsList: [],
      // 商品数量、体积、价格、预付款
      goodsNum: 0,
      goodsVolume: 0,
      goodsPrice: 0,
      advancePrice: 0,
      TotalPayment: {},

      //返利 （返利选项是否选中）
      //返利按钮是否选中
      isTradeChecked: true,
      // 预付比例
      advancePayRate: 0,
      totalAdvanceMoney: 0,
      drawerName: '',
      // 上个页面传过来的参数
      RouterParams: {
        flag: 1,
        counts: '1,2,1',
        ids: '2,3,2',
        mainId: 2
      },
      dissize: false,
      // 点击提交按钮提交的参数
      orgAndGroup: '',
      orderCartIds: {},
      // 配送方式
      trans: '',
      hisenseOff: false,
      officehis: [],
      officeCode: '',
      receivehis: '',
      officeHis: false,
      matklId: '',
      orgId: '',
      orgtitle: '',
      obj: {},
      // 提交给后台订单详情
      submitGoods: [],
      endTime: '',
      // 有效截止时间
      startTime: '',
      // 后台接口最大截止时间
      maxEndDate: '',
      // 面包屑
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/preferential",
          name: "preferential",
          title: "专区"
        }, {
          path: "/confirm/special",
          name: "specialZone",
          title: "专区结算"
        }
      ],
      // 按钮组件loading
      isLoading: false,
      // 页面加载loading
      pageLoadFlag: true,
      pageDetailLoadFlag: false, // 详情loading
      pager: {
        count: '',
        pageNo: 1,
        pageSize: 10
      },
      defaultPro: 0,
      // 是否使用返利
      tradeType: true,
      returnName: '',
      pageLoadFlagTable: false,
      saveMoneyValue: {},

      URLTO: '',
      proHide: false,
      saleShopList:[], // 销量所属门店
      receiveShop:'', // 销量所属门店名称
      salesShopInfoId:'',  // 销量所属门店id
      fwlbId:[],
      // 服务列表数据
      FWlist:[],
      provinceCode: '',//省
      cityCode: '',//市
      districtCode: '',//区
      townCode: '',//县乡
      companyList:[],
      fetching:false,
      FXName:'' , //需要展示的分销商名称
      FXid:'', // 选择的fenxiaoid
      ifDistributionSales:'',
      xslxList:[],
      xslxId:'',
      billingInfo:[],
      visibleKP:false,
      editInfo:{},
      text:'',
      detailInfo:{},
      addressHx:[],
      arrivalTimeStr:'',
      pickerOptions:{},
      nowDate:'',
      visibleaA: false,// 新建用户来源弹窗
      visibleTips: false,// 新建用户标签弹窗
      userSorceList: [],
      SorceIds: '',// 选中的用户来源
      userTipsList: [],
      TipsIds: [],// 选中的标签
      SelectItem:[],
      isAddressSupport:'',
      sexId:1,
      sexoptions: [{id: 1, name: '男士'}, {id: 2, name: '女士'}], // 用户性别列表
      serviceCharge:0,
      orderServiceFeeItemDtoList:[],
      warehouseInfoId:'',
      isShowLogisticsIcon:false,
      phone:'',
      text1:'',
      text2:'',
      haveDeliveryFee: false,
      transDefault:'',
      showSorce:false,
      sourceName:'',
      endOpen:false,
      startShowTime:'',
      TipsNames:[],
      countOrder:0
    };
  },

  mounted() {
    this.RouterParams = this.$route.query;
    let SCounts = this.$route.query.counts.split(',');
    this. countOrder = SCounts.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue), 0);
    let SIds = this.$route.query.ids.split(',')
    for (var i = 0; i < SIds.length; i++) {
      let obj = {
        id: SIds[i],
        count: SCounts[i]
      }
      this.submitGoods.push(obj);
    }

    this.returnName = '返回工程专区'
    //工程
    this.breadcrumbData = [{
      path: "/index",
      name: "index",
      title: "首页"
    },
      {
        path: "/engineering",
        name: "engineering",
        title: "工程专区"
      },
      {
        path: "/specialZonesGC",
        name: "specialZonesGC",
        title: "工程订单结算"
      }
    ]
    this.getXSLX()
    // 购物车查询
    this.carList()
    this.URLTO = saveMoneyType
    this.getuserSorceList()
    this.getuserTipList()
    const data1 = this.$getAlertInfoByCode('TS0261')
    const data2 = this.$getAlertInfoByCode('TS0262')
    this.text1 = data1.msgDesc
    this.text2 = data2.msgDesc
    // this.phone = data1.remark
  },
  watch:{
    GoodsList:{
      immediate: true,
      handler(val) {
        // let itema = []
        val.forEach((item, index)=>{
          item.idEdit = false
          // 获取政策台返信息
          const { PricingGroupCode, baseProductInfo } = item;
          try {
            ctsApi({
              serviceCode: "agentDirectDealerReturnPolicy",
              bigContractNo: "",
              companyCode: this.orgCode,
              contractNo: "",
              distributorsCisCode: "",
              dlMdmCode: this.$store.state.user.userInfo.customer.customerCode,
              model: baseProductInfo.modelSap || baseProductInfo.zzprdmodel,
              pricingGroup: PricingGroupCode,
              tableRow: "",
              isActivityArea: false
            }).then(res=>{
              if(res && res.data && res.data.data && res.data.data.model){
                item.isShowRebate = true;
                item.returnAmount = res.data.data.returnAmount;
                item.upperLimit = res.data.data.upperLimit;
                this.$set(this.GoodsList[index], index, item)
              }
            })
          } catch (error) {
          }
          checkRetailL({id:item.baseProductInfo.id}).then(res => {
            if(res.data && res.data.code == 0){
              const {type, money, bei, min, max, haveRetailL } = res.data;// 0不限制  1限制
              item.min = min;
              item.bei = bei;
              item.type = type;
              item.max = max;
              item.salePrice = this.$util.getFloat(Number(money),2)
              item.retailPrice = this.$util.getFloat(Number(money),2)
              item.haveRetailL = haveRetailL;
            }
          })

        })
      },
      deep:true
    },
    townCode:{
      immediate: true,
      handler(val) {
        if(this.townCode && this.detailInfo.distributionFlag != '1' && (this.trans == 502004||this.trans == 502008) ){
          this.getFWF()
        }
      }
    },
    warehouseInfoId:{
      immediate: true,
      handler(val) {
        if(this.townCode  && this.detailInfo.distributionFlag != '1' &&(this.trans == 502004||this.trans == 502008)){
          this.getFWF()
        }
      }
    },
    trans:{
      immediate: true,
      handler(val) {
        if(this.townCode  && this.detailInfo.distributionFlag != '1' &&(this.trans == 502004||this.trans == 502008) ){
          this.getFWF()
        }

      }
    },

  },
  methods: {
    disabledDateS(current) {
      // let nowDate = moment().format("YYYY-MM-DD")
      // 目前这个值是写死的 后期需要对接口
      let three = moment(this.startShowTime )
      // return current < moment().startOf('days') || current > new Date(this.$util.getDate30())
      return current < moment(this.startShowTime ) || current > three.add(29, 'd')
    },
    arrivalTimeFocus(){
      if( (!this.startShowTime && this.haveDeliveryFee)){
        this.$message.warning('请先填选相关信息')
        return
      }else {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(){
      if(this.arrivalTimeStr){
        this.endOpen = true;
        return
      }
    },
    // 期望送货日期时间选择
    arrivalTimeChange(value,dateString) {
      this.arrivalTimeStr = dateString;
      this.endOpen = false
    },
    // 查询服务费
    getFWF(){
      if( !this.townCode ){
        this.$message.warning('请选择省市区县')
        return
      }
      // if( !this.warehouseInfoId ){
      //   this.$message.warning('请选择统仓地址')
      //   return
      // }
      this.arrivalTimeStr = ''
      this.startShowTime = ''
      let orderServiceFeeItemDtoList = []
      this.GoodsList.forEach(item=>{
        orderServiceFeeItemDtoList.push({
          "productId": item.baseProductInfo.id,		//产品id
          "warehouseInfoId": "",			//仓库werks-lgort，例如2600-1532，dms代理商下单必传，其他情况传空
          "qty": item.quantity,
          zzprdmodel:item.baseProductInfo.zzprdmodel,
          orgId:this.orgId
        })
      })
      let data = {
        "townCode":  this.townCode,
        "countyCode": this.districtCode,
        "cityCode":  this.cityCode,
        "provinceCode":  this.provinceCode ,
        "warehouseInfoId":  this.warehouseInfoId?this.warehouseInfoId:'',			//发货仓库id，信天翁代理商下单必填，信天翁分销商下单或者dms下单必须传空
        // "shopId":  this.salesShopInfoId,			//门店id
        "documentSource":"1",			//dms下单传5，其他随便传个不是5的数就行
        "orderServiceFeeItemDtoList": orderServiceFeeItemDtoList,
        trans:this.trans
      }
      this.pageLoadFlag=true
      findMoneyBuyBegin(data).then(res => {
        // 获取送达时间
        if(res.data && res.data.data && res.data.data.timelinessDate){
          this.arrivalTimeStr =  res.data.data.timelinessDate?res.data.data.timelinessDate.replace(/-/g,"/"):''
          this.startShowTime = res.data.data.timelinessDate?res.data.data.timelinessDate.replace(/-/g,"/"):''
        } else{
          //  如果接口没返回数据 就默认当前日期加3天
          this.arrivalTimeStr =  this.$util.getDateSpecify(3)
          this.startShowTime = this.$util.getDateSpecify(3)
        }
        if(res.data.code == 0){
          this.serviceCharge = res.data.data.totalAmount
          this.orderServiceFeeItemDtoList = res.data.data.orderServiceFeeItemDtoList
          this.orderServiceFeeItemDtoList.forEach((itemFirst,indexFirst)=>{
            this.GoodsList.forEach((item,index)=>{
              if( itemFirst.productId == item.baseProductInfo.id ){
                this.$set(item,'serviceMoney',itemFirst.serviceFee)
                this.$nextTick(()=> {
                  this.$set(item,'serviceMoney',itemFirst.serviceFee)
                })
              }
            })
          })
          this.pageLoadFlag=false
          return
        }

        if(res.data.code == 1){
          this.pageLoadFlag=false
          this.serviceCharge = 0
          this.GoodsList.forEach((item,index)=>{
            this.$nextTick(()=> {
              this.$set(item,'serviceMoney',0)
            })
          })
        }

      }).catch(error=>{
        //  如果接口没返回数据 就默认当前日期加3天
        this.arrivalTimeStr =  this.$util.getDateSpecify(3)
        this.startShowTime = this.$util.getDateSpecify(3)
        this.pageLoadFlag=false

      })
    },
    changePrice(item){
      this.$forceUpdate()
    },
    changePhoneNum() {
      this.phoneCon =  this.phoneCon.replace(/\D/g, '')
      if( this.phoneCon.trim().length == 11&&this.salesShopInfoId&&(this.trans=='502004'||this.trans=='502008')){
        let data = {
          phone: this.phoneCon,
          shopInfoId:this.salesShopInfoId
        }
        this.TipsNames = []
        this.TipsIds = []
        getUserList(data).then(res=>{
          if(res.data.code == 0 && res.data.list && res.data.list.length > 0) {
            if(!this.contacts){
              this.contacts =  res.data.list[0].userName
            }
            this.SorceIds =  res.data.list[0].sourceId? res.data.list[0].sourceId:5007684
            this.sourceName =  res.data.list[0].sourceName?res.data.list[0].sourceName:'自然到访'
            this.showSorce = (this.SorceIds ==  res.data.list[0].sourceId)?true:false
            this.sexId = res.data.list[0].genderName=='男' ? 1 : (res.data.list[0].genderName=='女'? 2 : 1 )
            if(this.userTipsList.length>0){
              this.userTipsList.forEach(item=>{
                item.checked = false
                this.TipsNames = []
                this.TipsIds = []
              })
            }
            if( res.data.list[0].userLableList.length>0 && this.userTipsList.length > 0){
              this.userTipsList.forEach(item=>{
                res.data.list[0].userLableList.forEach(twoItem=>{
                  if(item.id == twoItem.labelId) {
                    item.checked = true
                    this.TipsIds.push(twoItem.labelId)
                    this.TipsNames.push(twoItem.labelName);
                  }
                })
              })
            }else {
              this.sexId =1
              // this.addressHx = []
              // this.provinceCode=''
              // this.cityCode=''
              // this.districtCode=''
              // this.townCode=''
              this.TipsIds=[]
              this.TipsNames=[]
              this.$nextTick(()=>{
                if(this.userTipsList.length>0){
                  this.userTipsList.forEach(item=>{
                    item.checked = false
                    if(item.id  == 5007817){
                      item.checked = true
                      this.TipsIds.push(item.id)
                      this.TipsNames.push(item.label);
                    }
                  })
                }
              })
              this.SorceIds = 5007684
              this.sourceName =  '自然到访'
              this.showSorce = false
            }
          } else {
            this.sexId = 1
            // this.addressHx = []
            // this.provinceCode=''
            // this.cityCode=''
            // this.districtCode=''
            // this.townCode=''
            this.showSorce = false
            this.TipsIds=[]
            this.TipsNames=[]
            this.$nextTick(()=>{
              if(this.userTipsList.length>0){
                this.userTipsList.forEach(item=>{
                  item.checked = false
                  if(item.id  == 5007817){
                    item.checked = true
                    this.TipsIds.push(item.id)
                    this.TipsNames.push(item.label);
                  }
                })
              }
            })
            this.SorceIds = 5007684
            this.sourceName =  '自然到访'
            this.showSorce = false
          }
        })
      }

    },
    changeSEX(e) {
      this.sexId = e.target.value;
    },
    openSource(){
      this.visibleaA = true
    },
    openTips(){
      this.visibleTips = true
    },
    // 获取用户来源
    getuserSorceList() {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findList(data).then(res => {
        if (res.data.code == 0) {
          this.userSorceList = res.data.data;
          if(this.userSorceList.length > 0) {
            this.userSorceList.forEach(item => {
              item.id = (item.id).toString();
              item.ischerk = false;
            });
          }

          this.pageLoadFlag = false;
        }
        this.pageLoadFlag = false;

      });
    },
    // 获取用户标签
    getuserTipList() {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findListLabel(data).then(res => {
        if (res.data.code == 0) {
          this.userTipsList = res.data.data;
          if (this.userTipsList.length > 0) {
            this.userTipsList.forEach(item => {
              item.checked = false;
            });
          }
          this.pageLoadFlag = false;

        }
        this.pageLoadFlag = false;
      });
    },
    // 点击
    changeSorceBtn(item) {
      if(this.SorceIds == item.id) {
        this.SorceIds = ''
        return
      }
      this.SorceIds = item.id;
      this.$forceUpdate();
    },
    changeBtnnew(item){
      this.SorceIds = item;
    },

    changeTipsBtn(item) {
      if (!item.checked) {
        if (this.TipsIds.length >= 8) {
          this.$message.warning("用户标签选择不能超过8个");
          return;
        }
      }
      item.checked = !item.checked;
      this.$forceUpdate();
      this.TipsIds = [];
      this.TipsNames = [];
      this.userTipsList.forEach(items => {
        if (items.checked == true) {
          this.TipsIds.push(items.id);
          this.TipsNames.push(items.label);
        }
      });
      this.$forceUpdate();
    },
    // 点击弹窗取消和确定的时候
    chengeOK(item,name) {
      this.TipsIds = [];
      this.TipsNames= [];
      this.TipsIds = item;
      this.TipsNames = name
      this.userTipsList.forEach(items=>{
        items.checked = false
        item.forEach(itemB=>{
          if(items.id == itemB) {
            items.checked = true
          }
        })
      })
    },
    // 用户来源点击确定 调接口 增加数据 成功的话赋值
    getReason(value,value2) {
      if (!value) {
        this.SorceIds = value2
        return;
      }
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        source: value,
        remark: ""
      };
      this.pageLoadFlag = true;
      saveInfo(data).then(res => {
        if (res.data.code == 0) {
          this.userSorceList.push(
            {
              id: res.data.data,
              source: value
            }
          );
          this.SorceIds = "";
          this.SorceIds = this.userSorceList[this.userSorceList.length - 1].id;
          this.$forceUpdate();
          this.pageLoadFlag = false;
        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }

      });
    },
    getReasonTip(value, value1) {

      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        label: value,
        remark: value1
      };
      saveInfoLabel(data).then(res => {
        if (res.data.code == 0) {
          this.userTipsList.push(
            {
              id: res.data.data,
              label: value,
              checked: true
            }
          );
          this.TipsIds.push(res.data.data);
          this.pageLoadFlag = false;

        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }
      });
    },
    onAddressChange(addressValArr) {
      // 老地址
      let oldAdress = this.districtCode
      // 长度如果等于2 乡镇就置空
      if(addressValArr&&addressValArr.length == 2) {
        this.districtCode = '';
      }
      // 长度如果等于3 区县就置空
      if(addressValArr&&addressValArr.length == 3) {
        this.townCode = '';
      }
      if(addressValArr.length > 0) {
        addressValArr.forEach((item, index) => {
          if (index == 0) {
            this.provinceCode = item;
          }
          if (index == 1) {
            this.cityCode = item;
          }
          if (index == 2) {
            this.districtCode = item;
          }
          if (index == 3) {
            this.townCode = item;
          }
        });
      } else {
        this.provinceCode = ''
        this.cityCode = ''
        this.districtCode = ''
        this.townCode = ''
      }
      // 如果之前的三级地址修改了  就清空详细地址
      if(oldAdress !=  this.districtCode ){
        this.userDetailAddress = ''
      }
      this.companyList = []
    },
    showModalKP() {
      this.editInfo={}
      this.text='add'
      this.visibleKP = true;
      this.$forceUpdate()
    },
    edit(VATItem,text){
      if(text == '编辑') {
        this.text='edit'
        this.editInfo = VATItem
        this.visibleKP = true;
        this.$forceUpdate()
      }
    },
    delKP(){
      // 需要增加校验
      this.billingInfo = []
    },
    // 增加开票信息
    addInfo(data){
      this.billingInfo = []
      if(data.invoiceTitleType == '202107230003') {
        data.invoiceTax = ''
        data.invoiceBanknum = ''
        data.invoiceBank = ''
      }
      this.billingInfo.push(JSON.parse(JSON.stringify(data)))
      if(this.billingInfo.length>0) {
        this.billingInfo.forEach(item=>{
          item.showButton = false
        })
      }
    },
    // 鼠标移入 展示查看详情
    mousemove(item) {
      item.showButton = true;
      this.$forceUpdate();
    },
    // 鼠标移出隐藏查看详情
    leave(item) {
      item.showButton = false;
      this.$forceUpdate();
    },
    // 获取销售类型
    getXSLX() {
      let data = {
        pid: 14934002578
      };
      focusonremarkplz(data).then(res => {
        if (res.data.code == 0) {
          this.xslxList = res.data.list;
          this.xslxId = res.data.list?res.data.list[0].code:''
        }
      });
    },
    blurAddress(){
      if(!this.districtCode) {
        this.userDetailAddress = ''
        return
      }
    },
    // 获取下拉地址
    getAdress(value) {
      if(!this.districtCode) {
        this.$message.warning('请先选择省市区县')
        this.$refs.address.blur()
        this.userDetailAddress = ''
        return
      }
      this.userDetailAddress = value;
      if(!this.userDetailAddress ){
        this.companyList = []
        return
      }
      if(this.userDetailAddress  && this.userDetailAddress.length < 4 && this.userDetailAddress.length > 0){
        this.companyList = []
        return
      }
      let data  = {
        searchstr: this.userDetailAddress,
        region:this.districtCode
      }
      this.fetching = true;
      getAdressapi(data).then(res => {
        if (res.data.data!==null && res.data.data.pois && res.data.data.pois.length > 0 && res.data.data.pois !== null) {
          this.companyList = [...new Set(res.data.data.pois)]; //缓存列表数据，前端分页
        } else {
          this.companyList =[]
        }
        this.fetching = false;
      });

    },
    //查询数据
    search(vaule,e) {
    },
    async sumbitaaa() {
      this.orderSubmit()
      return
    },
    // 服务列表查询
    getService() {
      let data  = {
        distributionFlag: this.detailInfo.distributionFlag
      }
      serviceInformation(data).then(res=>{
        this.FWlist = res.data
      }) 
    },
    // 销量所属门店查询
    getsaleShopList() {
      // 如果是工程订单 修改查询销量门店接口
      let data = {
        orgId: this.orgId,
        materialGroupId: this.matklId,
        ifEngineering:'1',
        searchKeyWords:''
      }
      SalesShopInfoList1(data).then(res=>{
        if( res.data.list.length > 0) {
          this.saleShopList = res.data.list
          // this.receiveShop = res.data.list[0].customerShopName;
          // this.salesShopInfoId = res.data.list[0].customerShopId;
        //   this.FXName = res.data.list[0].customerInfoName
        //   this.FXid = res.data.list[0].customerInfoId
        //   this.ifDistributionSales = res.data.list[0].ifDistributorShop
        }
      })

    },
    // 更改销量所属于门店
    handleChangeshopList(value, e) {
      this.receiveShop = value?value:'';
      this.salesShopInfoId = e?e.key:'';
      // 如果是工程订单选择的销量所属门店门店为分销商 展示分销商的名称 否则不显示
      let FXlIST = []
      FXlIST = this.saleShopList.filter((item)=>{
        return item.customerShopId == this.salesShopInfoId
      })
      if(FXlIST.length>0){
        // 修改为和移动端一致 之前是有判断  this.ifDistributionSales  == 1才赋值  现在都赋值
        this.FXName = FXlIST[0].customerInfoName
        this.FXid = FXlIST[0].customerInfoId
        this.ifDistributionSales = FXlIST[0].ifDistributorShop
      } else {
        this.ifDistributionSales = '0'
        this.FXName=''
        this.FXid = ''
      }
    },
    // 在线充值
    saveMoneyOnline() {
      saveMoney().then(res => {
        this.saveMoneyValue = res.data;
        this.$nextTick(() => {
          var selfOrderPay = document.getElementById("selfOrderPay");
          selfOrderPay.submit();
        })
      })
    },
    // 充值记录
    saveMoneyOnlineHis() {
      saveHisetory().then(res => {
        this.saveMoneyValue = res.data;
        this.$nextTick(() => {
          var selfOrderPayh = document.getElementById("selfOrderPayh");
          selfOrderPayh.submit();
        })
      })
    },
    //工程编号
    
    async getEngineerOrderCode() {
      await getEngineerOrder().then(res => {
        this.EngineerOrderCode = res.data.engineeringOrders.find(i => {
           return i.id="14958449608"
        }).projectCode    
       })
     
    }
   ,
    //是否返利点击
    tradeOnChange(e) {
      this.isTradeChecked = e.target.checked;
    },
    // 购物车查询
    carList() {
      let data = {
        mainId: this.RouterParams.mainId,
        flag: this.RouterParams.flag,
        count: this.countOrder
      }
      this.pageDetailLoadFlag = true
      specialZone(data).then(res => {
      
        this.detailInfo = res.data.detail
        // this.detailInfo.fwOrgName = 11111111
        if (res.data) {
          const timer = setTimeout(()=>{
            this.pageDetailLoadFlag = false;
            clearTimeout(timer)
          },500)
            
        }
        // 组织编码 物料组编码
        this.matklId = res.data.matklId;
        this.orgId = res.data.orgId;
        let data = {
          orgId: this.orgId,
          id: this.$store.state.user.userInfo.customer.id, // 商家id
        }
        checkIsOneProject(data).then(res=>{
          this.ifOnece = res.data.data
        })
        // if(res.data.)
        // 判断是否是统仓
        this.shareFlag = res.data.shareFlag;
        // 时间选择
        // this.defaultTime = res.data.minData;
        // 有效期默认显示日期
        this.startTime = res.data.maxDate
        if (res.data.shareFlag == 'Y') {
          this.houseAddress = '统仓地址'
          // this.distributors = true
          this.officeHis = true;
          this.dissize = true
          // 海信办事处地址
          if(res.data.office.length>0) {
            this.officehis = res.data.office;
            this.officeCode = res.data.office[0].code;
            this.receivehis = res.data.office[0].name;
          }
          // 为统仓收货时 联系人不可修改
          this.TCInt = true;
        } else if (res.data.custMarketModels && res.data.custMarketModels.length > 0 ) {
          // 分销商地址
          this.choseDistribution = res.data.custMarketModels
          // 当用户选择直配到分销商时
          // 获取分销商列表
          this.DisList = res.data.custMarketModels;
          // 分销商id
          this.distributionAdd.DISCODE = res.data.custMarketModels[0].customerInfoId;
          // 分销商名称 
          this.distrName = res.data.custMarketModels[0].customerInfoName
          // 预付比例
          this.advancePayRate = res.data.advancePayRate;
          // 请求分销商 地址参数 orgId
          this.DISADDPARAMS.orgId = res.data.orgId; // 接口没有这个参数
          this.DISADDPARAMS.matklId = res.data.matklId;
          this.DISADDPARAMS.customerId = res.data.custMarketModels[0].customerInfoId;
          // this.getDISADDList();
        }
        //   // 是否可以使用返利 01可用 02不可用 默认选中
        if (res.data.tradeType) {
          this.tradeType = res.data.tradeType == '01' ? true : false;
          this.isTradeChecked = res.data.tradeType == '01' ? true : false;
        } else {
          this.tradeType = false;
          this.isTradeChecked =false;
            this.$forceUpdate();
        }

        //  要求到货周

        //   // 点击提交按钮提交参数
        this.orgAndGroup = res.data.matklId
        this.maxEndDate = res.data.maxDate
        //   // end 点击提交按钮参数

        this.obj = res.data;
        // 配送方式
        if(res.data.deliveryList.length > 0) {
          this.options = res.data.deliveryList;
          this.isShowLogisticsIcon = this.options.some(el => [502004,502008,502007].includes(el.id))
          // 默认配送方式id
          this.trans = res.data.deliveryList[0].id
          if( (this.trans == 502004||this.trans == 502008) && this.detailInfo.distributionFlag != '1'){
            this.haveDeliveryFee = true
          } else{
            this.haveDeliveryFee = false
          }
        }
        
        // 开票户头
        if( res.data.billToDTOs.length> 0) {
          this.billToDTOs = res.data.billToDTOs;
          // 开票户头ID 及 公司名称
          this.invoice = this.billToDTOs[0].drawerName;
          this.invoiceId = this.billToDTOs[0].id;
        }
      
        // 省 list
        this.provices = res.data.provices;
        this.provinceCode = res.data.defaultProvice;
        this.cityCode = res.data.defaultCity;
        this.districtCode = res.data.defaultDistrict
        this.townCode = res.data.defaultTown
        this.addressHx = [ this.provinceCode,this.cityCode ,this.districtCode,this.townCode]
        // this.proObj.proviceCode = res.data.defaultProvice;
        // 获取市区
        this.choseCity(res.data.defaultProvice)

        // 收货地址
        this.orderDis = res.data.customerAddressDTOs;
        if( res.data.customerAddressDTOs.length > 0) {
          this.receive = res.data.customerAddressDTOs[0].address;
          this.receiveID = res.data.customerAddressDTOs[0].id;
          this.warehouseInfoId =  res.data.customerAddressDTOs[0].shareWarehouseId;
        }
        if(res.data.customerAddressDTOs.length > 0) {
          //  初始化获取手机号
          getPhone({
            sendToId: res.data.customerAddressDTOs[0].id
          }).then(res => {
            this.contacts = res.data.contact == "无" ? '' : res.data.contact;
            this.phoneCon = res.data.phone == "无" ? '' : res.data.phone;
          })
        }

        // 商品行数据
        var newarray = [];
        for (var j = 0; j < this.submitGoods.length; j++) {
          for (var i = 0; i < res.data.detailList.length; i++) {
            if (this.submitGoods[j].id == res.data.detailList[i].id) {
              res.data.detailList[i].quantity = Number(this.submitGoods[j].count);
              res.data.detailList[i].code = res.data.detail.code;
              newarray.push(res.data.detailList[i])
            }
          }
        }
        this.GoodsList = newarray;
        //商品件数 体积合计 合计金额
        let num = 0,
          volume = 0,
          amount = 0;
        //返利金额 列表计算返利比例后的返利金额 与 this.TotalPayment.rebateAccount返利金额相比 取较小值
        let rebate = 0;
        for (const item of this.GoodsList) {
          num += Number(item.quantity);
          volume += item.quantity * item.loadVolume;
          amount += item.quantity * item.billPrice;
          //返利金额
          rebate += item.quantity * item.billPrice * (item.rebate / 100);
        };
        this.goodsNum = num;
        this.goodsVolume = this.$util.getFloat(volume, 3);
        this.goodsPrice = this.$util.getFloat(amount, 2);

        // 支付数据
        this.TotalPayment = res.data.balanceDTO;
        this.TotalPayment.rebateAccount = this.TotalPayment.rebateAccount < rebate ? this.TotalPayment.rebateAccount : this.$util.getFloat(rebate, 2);
        this.totalAdvanceMoney = res.data.balanceDTO.totalAdvanceMoney;
        this.suppilers = res.data.detail.fwOrgName + '-' + res.data.detail.matklName
        this.orgCode = res.data.detail.fwOrgCode
        this.getsaleShopList()
        this.getService()
        if(this.orderDis.length > 0 ) {
          let obj = this.orderDis.find((i)=>{
            return i.id ===  res.data.customerAddressDTOs[0].id;//筛选出匹配数据
          });

        }
      }).catch(err => {
        console.log(err);
        const timer = setTimeout(()=>{
          this.pageDetailLoadFlag = false;
          clearTimeout(timer)
        },500)
      })
    },
    // 子组件选择商品
    // 查询库存数
    stock(goodsData, i) {
      getNumber({
        code: goodsData.code,
        orgId: goodsData.orgId
      }).then(resD => {
        var newObject = Object.assign(this.GoodsList[i], resD.data[i])
        this.GoodsList[i] = newObject;
      })
    },
    // 省 选择
    handleProvinceChange() {
      this.choseCity(this.provinceCode);
      this.cityCode = '';
      this.districtCode = '';
      this.townCode = '';
    },
    // 获取城市
    choseCity(proviceCode) {
      this.getCity = [];
      this.getTown = [];
      this.qu = [];
      if(!proviceCode){
        return;
      }
      let data = {
        proviceCode
      }
      cityChange(data).then(res => {
        this.getCity = res.data;
        //获取城镇区
        this.getTowns(this.cityCode)
      }).catch(err => {
        console.log(err);
      })

    },
    // 获取城镇区
    getTowns(cityCode) {
      this.getTown = [];
      this.qu = [];
      if(!cityCode){
        return;
      }
      let data = {
        cityCode
      }
      townChange(data).then(res => {
        this.getTown = res.data;
        this.getqu(this.districtCode);
      }).catch(err => {
        console.log(err);
      })

    },
    // 获取城镇下一家
    getqu(districtCode) {
      this.qu = [];
      if(!districtCode){
        return;
      }
      let data = {
        districtCode
      }
      getQu(data).then(res => {
          // this.townCode = res.data[0].townCode;
          this.qu = res.data;
        })
        .catch(err => {
          console.log(err);
        })

    },
    // 城市选择
    handleCityChange() {
      this.districtCode = '';
      this.townCode = '';
      this.getTowns(this.cityCode)
    },
    // 城镇选择
    handleTownChange() {
      this.townCode = '';
      this.getqu(this.districtCode);
    },
    // 城镇下一级 选择
    handleZhenChange() {
    },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {

      this.visible = false;
    },
    moreBill() {

    },
    onSalesType(e){
      this.xslxId = e.target.value
    },
    onGiveType(e) {
      this.trans = this.options[e.target.value].id;
      if( (this.trans==502004||this.trans==502008) && this.detailInfo.distributionFlag != '1'){
        this.haveDeliveryFee = true
      } else{
        this.haveDeliveryFee = false
      }
      // if (
      // this.houseAddress != '统仓地址') {
      // if (e.target.value == 3) {
      // 修改判断如果选择选项为直配到用户
      if( this.trans == 502004 || this.trans == 502007||this.trans==502008) {
        this.distributors = false;
        this.userAdderss = true;
        // this.getPeople(this.receiveID)
      } else if (this.trans == 502005) {
        if (!this.DisList || this.DisList.length == 0) {
          this.$warning({
            content: '暂无可选分销商，请选择其他配送方式',
          });
          return;
        }
        this.distributors = true;
        this.userAdderss = false
        // 分销商 分销地址
        this.getDISADDList()
        // this.showAnotheer()
      } else {
        this.distributors = false;
        this.userAdderss = false;
        // getPhone({
        //     sendToId: this.receiveID
        // }).then(res => {
        //     this.contacts = res.data.contact == '无' ? '' : res.data.contact;
        //     this.phoneCon = res.data.phone == '无' ? '' : res.data.phone;
        // })
      }
      // }
    },

    // 分销商 分销地址
    showAnotheer() {
        getPhone({
            sendToId: this.distributionAdd.DISADD
        }).then(res => {

            this.contacts = res.data.contact == "无" ? '' : res.data.contact;
            this.phoneCon = res.data.phone == "无" ? '' : res.data.phone;
        })
    },
    onSubmit() { //form
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          console.log(' submit!!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 点击选择开票户头
    choseAccount() {
      // axios请求后台开票户头
      this.accountShow = !this.accountShow;
    },
    // 开票户头相关函数
    handleChange(value, e) {

      this.invoiceId = e.data.key
      this.invoice = value;
      this.accountShow = !this.accountShow;
    },
    handleBlur() {},
    handleFocus() {},
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    // 用户级联选择改变
    cityChange(value) {
    },
    // 期望送货日期时间选择
    arrivalTimeChange1(value,dateString) {
      this.arrivalTimeStr = dateString;
    },
    // 直送用户/送装一体地区校验
    deliveryAddressCheck() {
      const orgparamsList = this.GoodsList.map(el => {
        return {
          orgId: this.orgId,
          matklId: el.baseProductInfo.matklCode
        }
      })
      let data = {
        "serviceCode":"cisDeliveryAddressCheck",
        "provinceCode":this.provinceCode,
        "cityCode":this.cityCode,
        "districtCode":this.districtCode,
        "townCode":this.townCode,
        "custId":this.$store.state.user.userInfo.customer.id,
        orgparamsList:orgparamsList,
        orderType:1,
        deliveryMethod:this.trans=='502004'?'04':'08',
        haveDeliveryFee:this.haveDeliveryFee?1:0,// 是否展示服务费
      }
      // ctsApi(data).then(res=>{
      //   if(res.data.code == 0) {
      //   } else {
      //     this.$message.warning(res.data.msg)
      //     return
      //   }
      // })
      return ctsApi(data)
    },
    async isCheckShipTo(){
      let res = await checkShipTo({shipToId:this.receiveID })
      console.log('res22',res)
      return res.data.data.isInvalidRegion
    },
    getAddress(e) {
      console.log('eeeee',e)
      this.newTown = e[3]
      console.log('this.newTown ',this.newTown )
    },
    // 点击提交订单按钮
    async orderSubmit() {
      if([502001, 502002].includes(this.trans) && this.houseAddress == '仓库地址' && !this.newTown) {
        let checkRes= await this.isCheckShipTo()
        if(checkRes == 1) {
          // 上面不符合条件的弹出弹窗
          this.visibleAddress = true
          return
        }
      }
      if(['502004', '502008'].includes(this.trans)) {
        // 判断下单产品是否支持直送用户
        const index1 = this.options.findIndex(el => el.code == '04')
        if(['502004'].includes(this.trans) && this.options[index1].msg) {
          this.$message.warning(this.options[index1].msg)
          return false
        }
        // 判断下单产品是否支持送装一体
        const index2 = this.options.findIndex(el => el.code == '08')
        if(['502008'].includes(this.trans) && this.options[index2].msg) {
          this.$message.warning(this.options[index2].msg)
          return false
        }
        if(!this.townCode) {
          this.$message.warning('请选择收货地区')
          return false
        }

        if(this.userDetailAddress == '' ){
          this.$message.warning('请填写详细地址');
          this.isLoading = false
          return;
        }
        if(!this.salesShopInfoId) {
          this.$message.warning('请选择销量所属门店')
          return false
        }

        // if(!this.SorceIds) {
        //   this.$message.warning('请选择用户来源')
        //   return false
        // }
        // if(this.TipsIds.length == 0) {
        //   this.$message.warning('请选择用户标签')
        //   return false
        // }
        const res =  await this.deliveryAddressCheck()
        if(res.data.code == 1) {
          this.$message.warning(res.data.msg)
          return false
        }

      }
      if (this.invoiceId == '') {
        this.$message.warning('请选择开票户头');
        this.isLoading = false
        return;
      }
      if (this.startTime == '') {
        this.$message.warning('请填写截止日期');
        this.isLoading = false
        return;
      }
      if (!this.orderDis || this.orderDis.length == 0) {
        this.$message.warning('暂无可选收货地址，无法提交');
        this.isLoading = false
        return;
      }
      if (this.receiveID == '') {
        this.$message.warning('请填写收货地址');
        this.isLoading = false
        return;
      }
      
      if (this.userAdderss) {
        if(!(this.districtCode && this.townCode)){
          this.$message.warning('请选择直配地址');
          this.isLoading = false
          return;
        }
        if(this.userDetailAddress == '' ){
          this.$message.warning('请填写详细地址');
          this.isLoading = false
          return;
        }
      }
      if ( !this.contacts) {
        this.$message.warning('请填写联系人信息');
        this.isLoading = false
        return;
      }
      if ( !this.phoneCon) {
        this.$message.warning('请填写手机号');
        this.isLoading = false
        return;
      }
      if (this.phoneCon.trim().length !== 11) {
        this.$message.warning("手机号不正确，请重新填写");
        return;
      }
      if(this.ifOnece && !this.orderNumber){
        this.$message.warning("订单备注必填，请填写财务中台收款认领通知号后10位。");
        return;
      }
     if(this.trans== '502004' ||this.trans== '502008'||this.trans== '502007'){
      if(!this.salesShopInfoId) {
        this.$message.warning('销量所属门店必填，若无门店，请联系中心创建门店')
        return false
      }
     }
      // if (this.goodsPrice > this.TotalPayment.canUseMoney) {
      //   this.$message.warning('订单金额不得大于账户可用余额');
      //   this.isLoading = false
      //   return;
      // }
      if( this.trans== '502004' ||this.trans== '502008') {
        // 判断零售价格在建议零售价0.5倍到1倍之间。
        let arr = this.GoodsList
        for(let i = 0; i < arr.length; i++){
          if (!arr[i].salePrice) {
            this.$message.warning(`请输入第${i+1}件商品的零售价格`)
            return false
          }
          if (arr[i].type == 1 && arr[i].salePrice) {
            if (Number(arr[i].salePrice) < arr[i].min || Number(arr[i].salePrice) > arr[i].max) {
              if (arr[i].haveRetailL) {
                this.$message.warning(`请在默认建议零售价的${arr[i].bei}倍范围内录入价格`);
              } else {
                this.$message.warning(`您录入的价格不符合产品公司规定，请按要求录入。`);
              }
              return;
            }
          }
        }
      }


      this.engineerSub()
    },
    // 时间更改
    handleTimeChange(e, defaultTime) {

      this.startTime = defaultTime;
    },
    getNowFormatDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    // 控制可选时间范围
    disabledDate(current) {
      let nowDate = moment().format("YYYY-MM-DD")
      // return current < moment().startOf('days') || current > new Date(this.$util.getDate30())
      return current < moment(nowDate) || current > moment(nowDate + ' 23:59:59').add(29, 'd')
    },


    //工程订单提交
    engineerSub() {
      let list =  this.GoodsList.map(el => {
        return {
          count: el.quantity,
          id: el.id,
          retailPrice: el.salePrice || 0
        }
      })
      // 如果一次性工程为false 所有的值置空
      if(!this.ifOnece && this.billingInfo.length>0){
          this.billingInfo[0].invoiceType = ''
          this.billingInfo[0].taxRateId = ''
          this.billingInfo[0].invoiceTitleType = ''
          this.billingInfo[0].invoiceTitle = ''
          this.billingInfo[0].invoiceTax = ''
          this.billingInfo[0].invoiceAddress = ''
          this.billingInfo[0].invoicePhone = ''
          this.billingInfo[0].invoiceAddress = ''
          this.billingInfo[0].invoiceBank = ''
          this.billingInfo[0].invoiceBanknum = ''
          this.billingInfo[0].invoiceReceiveAddr = ''
          this.billingInfo[0].invoiceReceiveMail = ''
      }
      let data = {
        orderCartList:this.trans=='502004' ?JSON.stringify(list):[],
        //供应商和物料组
        orgMatkl: this.obj.orgId + '-' + this.obj.matklId,
        id: this.RouterParams.mainId,
        // 送达方 商家id 开票
        billToId: this.invoiceId,
        // 统仓收货地址  收货地址 receiveID
        shipToId: this.receiveID,
        // 分销商id   这里id 有问题
        // fxShipToId: this.distributors ? this.distributionAdd.DISCODE : '',
        fxShipToAddress: this.distributors ? this.distributionAdd.DISADD : '',
        deliveryTypeId: this.trans,
        // city: this.proObj.cityCode,
        contact: this.contacts,
        contactPhone: this.phoneCon,
        endDate: this.startTime,
        // 手填的地址
        address: this.userDetailAddress,
        maxEndDate: this.maxEndDate,
        arrivalTimeStr:this.haveDeliveryFee&&this.arrivalTimeStr?this.arrivalTimeStr.replace(/\//g,"-")+' 23:59:59':'',
        count: JSON.stringify(list),
        orderRebate: this.isTradeChecked ? "Y" : "N",
        officeId: this.officeCode,
        // 客户采购单号
        purchaseCode: this.orderNumber,
        // 如果有销量所属门店并且配送方式为直送用户或者直配工地 传销量所属于门店id
        salesShopInfoId: (this.salesShopInfoId && (this.trans == 502004 || this.trans == 502007||this.trans==502008))? this.salesShopInfoId : '',
        serviceTypeCodes:(this.trans == 502004||this.trans == 502008) ? this.fwlbId.join(',') : '',
        countyId: this.userAdderss ? this.districtCode : '',
        town: this.userAdderss ? this.townCode : '',
        ifDistributionSales:this.ifDistributionSales,
        // fxShipToId:this.FXid,
        // 直配到用户传参门店所属分销商id 直配到分销商取分销商选项的id 其余的传空
        fxShipToId:this.FXid?this.FXid:'' ,
        projectSalesTypeId: this.xslxId,
        invoiceType:this.billingInfo.length>0?this.billingInfo[0].invoiceType:'',
        taxRateId:this.billingInfo.length>0?this.billingInfo[0].taxRateId:'',
        invoiceTitleType:this.billingInfo.length >0 && this.billingInfo[0].invoiceTitleType?this.billingInfo[0].invoiceTitleType:'',
        invoiceTitle:this.billingInfo.length>0?this.billingInfo[0].invoiceTitle:'',
        invoiceTax:this.billingInfo.length>0?this.billingInfo[0].invoiceTax:'',
        invoiceAddress:this.billingInfo.length>0?this.billingInfo[0].invoiceAddress:'',
        invoicePhone:this.billingInfo.length>0?this.billingInfo[0].invoicePhone:'',
        invoiceBank:this.billingInfo.length>0?this.billingInfo[0].invoiceBank:'',
        invoiceBanknum:this.billingInfo.length>0?this.billingInfo[0].invoiceBanknum:'',
        invoiceReceiveAddr:this.billingInfo.length>0?this.billingInfo[0].invoiceReceiveAddr:'',
        invoiceReceiveMail:this.billingInfo.length>0?this.billingInfo[0].invoiceReceiveMail:'',
        gender: this.trans != '502004' ? '' : this.sexId,// 性别
        sourceId: this.trans != '502004' || this.SorceIds.length<=0? '' : this.SorceIds,// 用户来源
        labelList: this.trans != '502004'|| this.TipsNames.length<=0 ? '' : this.TipsNames.join(','), // 用户标签
        validTown:(this.trans == 502001||this.trans == 502002)&& this.houseAddress == '仓库地址'?this.newTown:''
      }
      this.isLoading = true;
      engineerSubmit(data).then(res => {
        if (res.data.code == 1) {
          this.$message.warning(res.data.msg)
          this.isLoading = false;
          this.newTown=''
        }else if (res.data.code == 400) {
          // this.$message.warning(res.data.msg)
          this.isLoading = false;
          this.newTown=''
        } else if (!res.data.b2bOrderCode) {
          this.$message.warning(res.data.error)
          this.isLoading = false;
          this.newTown=''
        } else {
          this.isLoading = false;
          this.newTown=''
          if(res.data.serviceFeeCode) {
            this.$router.push({
              path: "/serviceFeeModule/pay",
              query: {
                id:res.data.serviceFeeCode,
                batch:0,
                b2bOrderCode: res.data.b2bOrderCode || res.data.orderCode,
                orgName: '订单号'
              }
            });
            return
          }
          this.$router.push({
            path: '/confirm/confirmsuccess',
            query: {
              b2bOrderCode: res.data.b2bOrderCode,
              orgName: '订单号'
            }
          })

        }
      })
    },
    // 获取子组件选中商品
    sonCheckList(data) {
      let cheacked = this.sonChectItme.indexOf(data)
      if (this.sonChectItme.indexOf(data) == -1) {
        this.sonChectItme.push(data);
      } else {
        this.sonChectItme.splice(cheacked, 1)
      }
    },
    //   点击返回购物车
    goCar() {
      this.$router.go('-1')
    },
    // 收货地址点击选择
    receiveAddress() {
      this.receiveAdd = !this.receiveAdd;
    },
    // 收货地址下拉框选择
    handleChangeAddress(value, e) {
      let obj = this.orderDis.find((i)=>{
        return i.id ===  e.key;//筛选出匹配数据
      });


      this.receive = value;
      // 收货地址 选中 id
      this.receiveID = e.key;
      // this.SAddressChange(e.key)
      this.receiveAdd = !this.receiveAdd;
      // 获取手机号
      getPhone({
        sendToId: e.key
      }).then(res => {

        this.contacts = res.data.contact == "无" ? '' : res.data.contact;
        this.phoneCon = res.data.phone == "无" ? '' : res.data.phone;
      })
    },
    // 海信办事处
    hishandleChangeAddress(value, e) {
      this.receivehis = value;
      this.officeCode = e.key;
      this.hisenseOff = !this.hisenseOff;
    },
    // 切换收货地址
    SAddressChange(id) {
      showAnother({
        id
      }).then(res => {

      }).catch(err => {
        console.log(err)
      })
    },
    // 分销商显示隐藏
    choseDistr() {
      this.distr = !this.distr;
    },
    // 切换分销商
    handleChangeDistr(value, e) {
      this.distrName = value;
      this.DISADDPARAMS.customerId = e.key;
      this.distributionAdd.DISCODE = e.key;
      this.distr = false;
      this.customerId = e.key
      this.getDISADDList()
      // this.getPeople(e.key)
    },
    // 分销商切换获取分销商地址list
    getDISADDList() {
      let data = this.DISADDPARAMS;
      getDISAdd(data).then(res => {
        if(res.data.length > 0){
          this.choseDistribution = res.data;
          this.distrAddress = res.data[0].address;
          this.distributionAdd.DISADD = res.data[0].id
          //切换分销商获取地址列表请求联系人
          this.getPeople(res.data[0].id);
        }
      }).catch(err => {
        console.log(err);
      })
    },
    distrAddShow() {
      this.distrAdd = !this.distrAdd;
    },
    // 选择经销商地址
    handleChangeDistrAdd(value, e) {
      this.distributionAdd.DISADD = e.key
      this.distrAdd = false;
      this.distrAddress = value;
      this.getPeople(e.key);
    },
    // 获取手机号
    getPeople(sendToId) {
        getPhone({
            sendToId
        }).then(res => {
            this.contacts = res.data.contact == "无" ? '' : res.data.contact;
            this.phoneCon = res.data.phone == "无" ? '' : res.data.phone;
        })
    },
    addItem() {
    },
    // 监听输入变化
    handleChangeInt(e) {
    },
    // 弹框
    ToDetail() {
      this.modelShow = true;
      this.pageLoadFlagTable = true;
      var data = {
        orgIdParam: this.orgId,
        matklIdParam: this.matklId,
        weekName: this.selected,
        purchaseType: this.RouterParams.purchaseType,
        pageNo:1,
        pageSize:10000,
      }
      quotaDeatail(data).then(res => {
        this.pageLoadFlagTable = false;
        this.dataAll = res.data.dataList.list;
        this.data =res.data.dataList.list.slice(0, 10)
        this.pager.count = res.data.dataList.list.length;
      })
    },
    // 分页
    // 分页
    pagesiezes(data) {
      var start = (data - 1) * this.pager.pageSize;
      this.data = this.dataAll.slice(start, start + this.pager.pageSize)
      this.pager.pageSize;
    },

    // 海信办事处
    hisenseOffice() {
      this.hisenseOff = !this.hisenseOff;
    },
    // 智能识别
    recognitionFun(res){
      const townCode = res.levelAddress.town_code;
      // 收货地址
      this.addressHx = [townCode.slice(0,2).padEnd(12, 0),townCode.slice(0,4).padEnd(12, 0),townCode.slice(0,6).padEnd(12, 0),townCode.padEnd(12, 0)];
      // 收货地址 code
      this.provinceCode = townCode.slice(0,2).padEnd(12, 0);
      this.cityCode = townCode.slice(0,4).padEnd(12, 0);
      this.districtCode = townCode.slice(0,6).padEnd(12, 0);
      this.townCode = townCode.padEnd(12, 0);
      // 详细地址
      this.userDetailAddress = res.address;
      // 姓名
      this.contacts = res.userName;
      // 电话
      this.phoneCon = res.mobile;
    }
  }
}