<template>
    <div>
        <a-modal
          v-model="visible"
          @ok="handleOk"
          wrapClassName="cancelName"
          :width="648"
          :closable="false"
          centered
          :maskClosable="false"
          :confirm-loading="confirmLoading"
          @cancel="handleCancel">
          <div class="modal_close" @click="handleCancel">
            <span class="iconfont icon-danchuangguanbi-anniu modal_close_icon"></span>
          </div>
          <div class="modal_title_box">
            <span class="modal_title">
              <i class="modal_title_line"></i>添加发票信息
            </span>
          </div>
          <p class="leixing">
            <span style="width: 100px;text-align: right;">
               <span class="leixingxing" style="color: #D9161C">*</span>发票类型：
            </span>
              <button @click="tab('15691143852','GeneralTicket')" :class="{active:show=='15691143852'}">
                  纸质普票</button>
              <button @click="tab('15691143853','GeneralTicket1')" :class="{active:show=='15691143853'}">电子普票</button>
              <button @click="tab('15691143854','SpecialTicket')" :class="{active:show=='15691143854'}">增值税纸质专票</button>
          </p>
          <div class="onePart" v-if="cmp =='GeneralTicket'">
            <p class="first">
              <span class="leftTxt">
                 <span class="leixingxing">*</span>抬头类型：
              </span>
              <a-radio-group name="radioGroup" v-model="onePartInfo.invoiceTitleType" >
                <a-radio :value="'202107230003'">
                  个人
                </a-radio>
                <a-radio :value="'202107230004'">
                  企业
                </a-radio>
              </a-radio-group>
            </p>
            <p>
              <span class="leftTxt">
                <span class="leixingxing">*</span>发票抬头：
              </span>
              <a-input placeholder="请输入" v-model="onePartInfo.invoiceTitle"/>
            </p>
            <div class="aaa" v-if="onePartInfo.invoiceTitleType== '202107230003'">
              <p class=" adressBOX">
                <span class="leftTxt" style="width:138px!important;text-align: right">地址：</span>
                <a-textarea  class="address" v-model="onePartInfo.invoiceAddress" placeholder="请输入"/>
              </p>
              <p >
                <span class="leftTxt">电话：</span>
                <a-input  placeholder="请输入" v-model="onePartInfo.invoicePhone"/>
              </p>
              <p>
                <span class="leftTxt">收票电话：</span>
                <a-input v-model="onePartInfo.invoiceReceiveAddr"   placeholder="请输入"/>
              </p>
              <p>
                <span class="leftTxt">收票邮箱：</span>
                <a-input v-model="onePartInfo.invoiceReceiveMail"  placeholder="请输入"/>
              </p>
            </div>
            <div v-if="onePartInfo.invoiceTitleType== '202107230004'">
              <p>
                <span class="leftTxt">
                  <span class="leixingxing">*</span>企业税号：
                </span>
                <a-input  v-model="onePartInfo.invoiceTax" placeholder="请输入"/>
              </p>
              <p class="adressBOX">
                <span style="width:138px!important;text-align: right"><span class="leixingxing">*</span>注册地址：</span>
                <a-textarea class="address"   v-model="onePartInfo.invoiceAddress" placeholder="请输入"/>
              </p>
              <p>
                <span class="leftTxt">
                  <span class="leixingxing">*</span>
                  注册电话：
                </span>
                <a-input   v-model="onePartInfo.invoicePhone" placeholder="请输入"/>
              </p>
              <p>
                <span class="leftTxt">
                  <span class="leixingxing">*</span>
                  开户银行：
                </span>
                <a-input  v-model="onePartInfo.invoiceBank" placeholder="请输入"/>
              </p>
              <p>
                <span class="leftTxt">
                  <span class="leixingxing">*</span>
                  银行账号：
                </span>
                <a-input  v-model="onePartInfo.invoiceBanknum" placeholder="请输入"/>
              </p>
              <p>
                <span class="leftTxt">
                  收票电话：
                </span>
                <a-input v-model="onePartInfo.invoiceReceiveAddr"   placeholder="请输入"/>
              </p>
              <p>
                <span class="leftTxt">
                  收票邮箱：
                </span>
                <a-input v-model="onePartInfo.invoiceReceiveMail"  placeholder="请输入"/>
              </p>
            </div>
          </div>
<!--          -->
          <div class="TWOPart" v-if="cmp =='GeneralTicket1'">
            <p class="first">
              <span class="leftTxt">
                <span class="leixingxing">*</span>抬头类型：
              </span>
              <a-radio-group name="radioGroup" v-model="twoPartInfo.invoiceTitleType" >
                <a-radio :value="'202107230003'">
                  个人
                </a-radio>
                <a-radio :value="'202107230004'">
                  企业
                </a-radio>
              </a-radio-group>
            </p>
            <p>
              <span class="leftTxt">
                <span class="leixingxing">*</span>发票抬头：
              </span>
              <a-input placeholder="请输入" v-model="twoPartInfo.invoiceTitle"/>
            </p>
            <div class="aaa" v-if="twoPartInfo.invoiceTitleType== '202107230003'">
              <p class="adressBOX">
                <span class="leftTxt" style="width: 138px;text-align: right">地址：</span>
                <a-textarea  class="address"  v-model="twoPartInfo.invoiceAddress"  placeholder="请输入"/>
              </p>
              <p class="">
                <span class="leftTxt">电话：</span>
                <a-input  placeholder="请输入" v-model="twoPartInfo.invoicePhone"/>
              </p>
              <p>
                <span class="leftTxt">收票电话：</span>
                <a-input v-model="twoPartInfo.invoiceReceiveAddr"   placeholder="请输入"/>
              </p>
              <p>
                <span class="leftTxt">收票邮箱：</span>
                <a-input v-model="twoPartInfo.invoiceReceiveMail"  placeholder="请输入"/>
              </p>
            </div>
            <div v-if="twoPartInfo.invoiceTitleType== '202107230004'">
              <p>
                <span class="leftTxt">
                  <span class="leixingxing">*</span>企业税号：
                </span>
                <a-input  v-model="twoPartInfo.invoiceTax" placeholder="请输入"/>
              </p>
              <p class="adressBOX">
                <span class="leftTxt" style="width: 136px!important;text-align: right"><span class="leixingxing">*</span>注册地址：</span>
                <a-textarea class="address"    v-model="twoPartInfo.invoiceAddress" placeholder="请输入"/>
              </p>
              <p>
                <span class="leftTxt" >
                  <span class="leixingxing">*</span>
                  注册电话：
                </span>
                <a-input   v-model="twoPartInfo.invoicePhone" placeholder="请输入"/>
              </p>
              <p>
                <span class="leftTxt">
                  <span class="leixingxing">*</span>
                  开户银行：
                </span>
                <a-input  v-model="twoPartInfo.invoiceBank" placeholder="请输入"/>
              </p>
              <p>
                <span class="leftTxt">
                  <span class="leixingxing">*</span>
                  银行账号：
                </span>
                <a-input  v-model="twoPartInfo.invoiceBanknum" placeholder="请输入"/>
              </p>
              <p>
                <span class="leftTxt">
                  收票电话：
                </span>
                <a-input v-model="twoPartInfo.invoiceReceiveAddr"   placeholder="请输入"/>
              </p>
              <p>
                <span class="leftTxt">
                  收票邮箱：
                </span>
                <a-input v-model="twoPartInfo.invoiceReceiveMail"  placeholder="请输入"/>
              </p>
            </div>
          </div>
<!--          -->
          <div v-if="cmp =='SpecialTicket'" class="threePart">
            <p class="first">
             <span class="leftTxt">
                <span class="leixingxing">*</span>发票抬头：
             </span>
              <a-input   v-model="threePartInfo.invoiceTitle" placeholder="请输入"/>
            </p>
            <p>
              <span class="leftTxt">
                <span class="leixingxing">*</span>企业税号：
              </span>
              <a-input  v-model="threePartInfo.invoiceTax" placeholder="请输入"/>
            </p>
            <p class="adressBOX ">
              <span class="leftTxt" style="width:136px;text-align: right"><span class="leixingxing">*</span>注册地址：</span>
              <a-textarea  class="address"   v-model="threePartInfo.invoiceAddress" placeholder="请输入"/>
            </p>
            <p>
              <span class="leftTxt"><span class="leixingxing">*</span>注册电话：</span>
              <a-input  v-model="threePartInfo.invoicePhone" placeholder="请输入"/>
            </p>
            <p>
              <span class="leftTxt"><span class="leixingxing">*</span>开户银行：</span>
              <a-input   v-model="threePartInfo.invoiceBank" placeholder="请输入"/>
            </p>
            <p>
              <span class="leftTxt"><span class="leixingxing">*</span>银行账号：</span>
              <a-input v-model="threePartInfo.invoiceBanknum" placeholder="请输入"/>
            </p>
            <p>
              <span class="leftTxt">收票电话：</span>
              <a-input  v-model="threePartInfo.invoiceReceiveAddr" placeholder="请输入"/>
            </p>
            <p>
              <span class="leftTxt">收票邮箱：</span>
              <a-input  v-model="threePartInfo.invoiceReceiveMail"  placeholder="请输入"/>
            </p>
          </div>
        </a-modal>
    </div>
</template>
<script>
export default {
    components:{

    },
     props:{
       text:{
         type:String,
         default:''
       },
       visible:{
         type:Boolean,
         default:false
       },
       editInfo: {
         type: Object
       },
     },
  watch:{
    text(val,old) {
      if(val=='add') {
        this.cmp = 'GeneralTicket'
        this.show='15691143852'
        for(let objA in this.onePartInfo){
          this.onePartInfo[objA] = ''
        }
        this.onePartInfo.invoiceType = '15691143852'
        this.onePartInfo.taxRateId = '74584533'
        this.onePartInfo.invoiceTitleType = '202107230003'

        for(let objB in this.twoPartInfo){
          this.twoPartInfo[objB] = ''
        }
        this.twoPartInfo.invoiceType = '15691143853'
        this.twoPartInfo.taxRateId = '74584536'
        this.twoPartInfo.invoiceTitleType = '202107230003'
        for(let objC in this.threePartInfo){
          this.threePartInfo[objC] = ''
        }
        this.threePartInfo.invoiceType= '15691143854'
        this.threePartInfo.taxRateId = '74584532'
      }
    },
    editInfo(val,old) {
      if(val.invoiceType == '15691143852') {
        this.cmp = 'GeneralTicket'
        this.show='15691143852'
        this.onePartInfo = val
        this.onePartB = val
        for(let objB in this.twoPartInfo){
          this.twoPartInfo[objB] = ''
        }
        this.twoPartInfo.invoiceType = '15691143853'
        this.twoPartInfo.taxRateId = '74584536'
        this.twoPartInfo.invoiceTitleType = '202107230003'
        for(let objC in this.threePartInfo){
          this.threePartInfo[objC] = ''
        }
        this.threePartInfo.invoiceType= '15691143854'
        this.threePartInfo.taxRateId = '74584532'
      }
      if(val.invoiceType == '15691143853') {
        this.cmp = 'GeneralTicket1'
        this.show='15691143853'
        this.twoPartInfo =  val
        this.twoPartInfoB =   val

        for(let objA in this.onePartInfo){
          this.onePartInfo[objA] = ''
        }
        this.onePartInfo.invoiceType = '15691143852'
        this.onePartInfo.taxRateId = '74584533'
        this.onePartInfo.invoiceTitleType = '202107230003'
        for(let objC in this.threePartInfo){
          this.threePartInfo[objC] = ''
        }
        this.threePartInfo.invoiceType= '15691143854'
        this.threePartInfo.taxRateId = '74584532'
      }
      if(val.invoiceType == '15691143854') {
        this.cmp = 'SpecialTicket'
        this.show='15691143854'
        this.threePartInfo =   val
        this.threePartB =   val
        for(let objA in this.onePartInfo){
          this.onePartInfo[objA] = ''
        }
        this.onePartInfo.invoiceType = '15691143852'
        this.onePartInfo.taxRateId = '74584533'
        this.onePartInfo.invoiceTitleType = '202107230003'

        for(let objB in this.twoPartInfo){
          this.twoPartInfo[objB] = ''
        }
        this.twoPartInfo.invoiceType = '15691143853'
        this.twoPartInfo.taxRateId = '74584536'
        this.twoPartInfo.invoiceTitleType = '202107230003'
      }
    }
  },
    data() {
        return {
            confirmLoading: false,
            show: '15691143852',
            cmp: 'GeneralTicket',
            //纸质普票
            onePartB:{},
            onePartInfo: {
              invoiceType:'15691143852',
              taxRateId:'74584533',
              invoiceTitleType: "202107230003",
              invoiceTitle: '',//发票抬头
              invoiceAddress: '',//地址
              invoicePhone: '',//电话
              invoiceTax: '',//企业税票
              invoiceBank: '',//开户银行
              invoiceBanknum: '',//银行账号
              invoiceReceiveAddr: '',//收票电话
              invoiceReceiveMail: '',//收票邮箱
            },
            //电子普票
            twoPartInfoB:{},
            twoPartInfo: {
              invoiceType:'15691143853',
              taxRateId:'74584536',
              invoiceTitleType: "202107230003",
              invoiceTitle: '',//发票抬头
              invoiceAddress: '',//地址
              invoicePhone: '',//电话
              invoiceTax: '',//企业税票
              invoiceBank: '',//开户银行
              invoiceBanknum: '',//银行账号
              invoiceReceiveAddr: '',//收票电话
              invoiceReceiveMail: '',//收票邮箱
            },
            // 增值税纸质专票
            threePartB:{},
            threePartInfo:{
            invoiceType:'15691143854',
            taxRateId:'74584532',
            invoiceTitleType:'',
            invoiceTitle: '',//发票抬头
            invoiceTax: '',//企业税票
            invoiceAddress: '',//注册地址
            invoicePhone: '',//注册电话
            invoiceBank: '',//开户银行
            invoiceBanknum: '',//银行账号
            invoiceReceiveAddr: '',//收票电话
            invoiceReceiveMail: ''//收票邮箱
          }
        };
    },
    methods: {
        handleOk() {
          if(this.cmp =='GeneralTicket') {
            if(!this.onePartInfo.invoiceTitle) {
              this.$message.warning('请输入发票抬头')
              return
            }
            // if(!this.onePartInfo.invoiceAddress) {
            //   this.$message.warning('请输入地址')
            //   return
            // }
            if(this.onePartInfo.invoiceTitleType == '202107230004'
              &&!this.onePartInfo.invoiceTax) {
              this.$message.warning('请输入企业税号')
              return
            }
            if(this.onePartInfo.invoiceTitleType == '202107230004'
              &&!this.onePartInfo.invoiceAddress) {
              this.$message.warning('请输入注册地址')
              return
            }
            if(this.onePartInfo.invoiceTitleType == '202107230004'
              &&!this.onePartInfo.invoicePhone) {
              this.$message.warning('请输入注册电话')
              return
            }
            if(this.onePartInfo.invoiceTitleType == '202107230004'
              &&!this.onePartInfo.invoiceBank) {
              this.$message.warning('请输入开户银行')
              return
            }
            if(this.onePartInfo.invoiceTitleType == '202107230004'
              &&!this.onePartInfo.invoiceBanknum) {
              this.$message.warning('请输入银行账号')
              return
            }
            for(let objB in this.twoPartInfo){
              this.twoPartInfo[objB] = ''
            }
            this.twoPartInfo.invoiceType = '15691143853'
            this.twoPartInfo.taxRateId = '74584536'
            this.twoPartInfo.invoiceTitleType = '202107230003'
            for(let objC in this.threePartInfo){
              this.threePartInfo[objC] = ''
            }
            this.threePartInfo.invoiceType= '15691143854'
            this.threePartInfo.taxRateId = '74584532'
            this.$forceUpdate()
            this.$emit("addOk", this.onePartInfo);
          }
          if(this.cmp =='GeneralTicket1') {
            if(!this.twoPartInfo.invoiceTitle) {
              this.$message.warning('请输入发票抬头')
              return
            }
            // if(!this.twoPartInfo.invoiceAddress) {
            //   this.$message.warning('请输入地址')
            //   return
            // }
            if(this.twoPartInfo.invoiceTitleType == '202107230004'
              &&!this.twoPartInfo.invoiceTax) {
              this.$message.warning('请输入企业税号')
              return
            }
            if(this.twoPartInfo.invoiceTitleType == '202107230004'
              &&!this.twoPartInfo.invoiceAddress) {
              this.$message.warning('请输入注册地址')
              return
            }
            if(this.twoPartInfo.invoiceTitleType == '202107230004'
              &&!this.twoPartInfo.invoicePhone) {
              this.$message.warning('请输入注册电话')
              return
            }
            if(this.twoPartInfo.invoiceTitleType == '202107230004'
              &&!this.twoPartInfo.invoiceBank) {
              this.$message.warning('请输入开户银行')
              return
            }
            if(this.twoPartInfo.invoiceTitleType == '202107230004'
              &&!this.twoPartInfo.invoiceBanknum) {
              this.$message.warning('请输入银行账号')
              return
            }
            for(let objA in this.onePartInfo){
              this.onePartInfo[objA] = ''
            }
            this.onePartInfo.invoiceType = '15691143852'
            this.onePartInfo.taxRateId = '74584533'
            this.onePartInfo.invoiceTitleType = '202107230003'
            for(let objC in this.threePartInfo){
              this.threePartInfo[objC] = ''
            }
            this.threePartInfo.invoiceType= '15691143854'
            this.threePartInfo.taxRateId = '74584532'
            this.$forceUpdate()
            this.$emit("addOk", this.twoPartInfo);
          }
          if(this.cmp =='SpecialTicket'){
            if(!this.threePartInfo.invoiceTitle) {
              this.$message.warning('请输入发票抬头')
              return
            }
            if(!this.threePartInfo.invoiceTax) {
              this.$message.warning('请输入企业税号')
              return
            }

            if(!this.threePartInfo.invoiceAddress) {
              this.$message.warning('请输入注册地址')
              return
            }
            if(!this.threePartInfo.invoicePhone) {
              this.$message.warning('请输入注册电话')
              return
            }
            if(!this.threePartInfo.invoiceBank) {
              this.$message.warning('请输入开户银行')
              return
            }
            if(!this.threePartInfo.invoiceBanknum) {
              this.$message.warning('请输入银行账号')
              return
            }

            for(let objA in this.onePartInfo){
              this.onePartInfo[objA] = ''
            }
            this.onePartInfo.invoiceType = '15691143852'
            this.onePartInfo.taxRateId = '74584533'
            this.onePartInfo.invoiceTitleType = '202107230003'
            for(let objB in this.twoPartInfo){
              this.twoPartInfo[objB] = ''
            }
            this.twoPartInfo.invoiceType = '15691143853'
            this.twoPartInfo.taxRateId = '74584536'
            this.twoPartInfo.invoiceTitleType = '202107230003'
            // this.threePartInfo.invoiceTitleType = '202107230004'
            this.$forceUpdate()
            this.$emit("addOk", this.threePartInfo);

          }
          this.$emit("update:visible", false);
        },
        handleCancel(e) {
          for(let objA in this.onePartInfo){
            this.onePartInfo[objA] = ''
          }
          this.onePartInfo.invoiceType = '15691143852'
          this.onePartInfo.taxRateId = '74584533'
          this.onePartInfo.invoiceTitleType = '202107230003'

          for(let objB in this.twoPartInfo){
            this.twoPartInfo[objB] = ''
          }
          this.twoPartInfo.invoiceType = '15691143853'
          this.twoPartInfo.taxRateId = '74584536'
          this.twoPartInfo.invoiceTitleType = '202107230003'

          for(let objC in this.threePartInfo){
            this.threePartInfo[objC] = ''
          }

          this.threePartInfo.invoiceType= '15691143854'
          this.threePartInfo.taxRateId = '74584532'
          this.$forceUpdate()
          this.$emit("update:visible", false);
        },
        tab(index,text) {
            this.cmp = text
            this.show =index
            this.$forceUpdate()
        }
        
        
    },
};
</script>
<style scoped lang="less">


/deep/.ant-modal-content {
    width: 648px;
    border-radius: 10px;
}

/deep/.ant-modal-title {
    font-weight: 500;
    color: #262626;
    line-height: 22px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;


}

/deep/.ant-modal-header,
/deep/.ant-modal-footer {
    border: none;
}

/deep/.ant-modal-header::after {
    display: block;
    content: '';
    width: 100px;
    height: 10px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 10px;
    // z-index:-1;
    background: #E4F8F7;
}

/deep/.ant-modal-close-x:hover {
    background: #E4F8F7;
}

/deep/.ant-modal-body {
    border: none;
    padding: auto;

}

/deep/.ant-modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

/deep/.ant-modal-footer {
    /deep/.ant-btn{
        width: 120px;
            height: 40px;
            border-radius: 4px;
            border: 1px solid #CCCCCC;
            margin-left: 24px;
            margin-bottom: 30px;
    }
    
}
p>button {
    height: 32px;
    border-radius: 16px;
    text-align: center;
    line-height: 16px;
    color: #00AAA6;
    font-size: 13px;
    padding: 7px 14px;
    background-color: #fff;
    // margin-right: 9px;
    margin-left: 8px;
    border: 1px solid #EEEEEE;
    outline: none;
}
.leixing{
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      cursor: pointer;
    }
}

//p>span {
//    color: #D9161C;
//}

.active {
    color: #fff;
    background-color: #00AAA6;
}
/deep/ .cancelName{
  .ant-modal-content{
    text-align:center;
    border-radius: 10px;
    .ant-modal-body{
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      .cancelContent{
        display: flex;
        flex-wrap: wrap;
        width: 418px;
        .clickBtn{
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          border: 1px solid #EEEEEE;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #00AAA6;
          margin-right: 8px;
          margin-bottom: 16px;
          cursor: pointer;
          font-size: 13px;
          font-weight: 400;
        }
        .clickBtnActive {
          cursor: pointer;
          margin-right: 8px;
          padding: 0 14px 0 14px;
          height: 32px;
          border-radius: 16px;
          //border: 1px solid #EEEEEE;
          display: flex;
          background: #00AAA6;
          justify-content: center;
          align-items: center;
          color: #fff;
          margin-bottom: 16px;
          border: none;
          font-size: 13px;
          font-weight: 400;
        }
      }
      .cancelInfo{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 418px;
        margin-bottom: 24px;
        text-align: left;
        overflow: hidden;
        .cancleZi{
          width: 70px;
          font-size: 14px;
          font-weight: 400;
          color: #262626;
          line-height: 20px;
          margin-right: 8px;
          text-align: right;
        }
        .ant-input{
          width: 300px;
          height:32px;
          overflow: auto;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
        .ant-input-affix-wrapper{
          width: 400px;
        }
      }

      .modal_close {
        position: absolute;
        top: 0;
        right: 0;
        width: 42px;
        height: 42px;
        line-height: 42px;
        border-radius: 0 10px 0 0;
        text-align: center;
        cursor: pointer;
        .modal_close_icon {
          color: #aaa;
          font-size: 16px;
        }
      }
      .modal_close:hover {
        background: #E4F8F7;
        .modal_close_icon {
          color: #08ABA8;
        }
      }
      .modal_title_box {
        text-align: center;
        padding-bottom:50px;
        .modal_title {
          position: relative;
          color: #262626;
          text-align: center;
          z-index: 10;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing:1px;
          .modal_title_line {
            display: inline-block;
            width: 100%;
            position: absolute;
            top: 13px;
            left: 0;
            border-top: 10px solid #E4F8F7;
            z-index: -10;
          }
        }
      }
      .onePart{
        p {
          margin-bottom: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          .leftTxt{
            width: 100px;
            text-align: right;
          }
          input {
            width: 300px;
            height: 32px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #EEEEEE;
            padding-left: 16px;
          }

          .radio {
            width: 16px;
            height: 16px;
            border: 1px solid #00AAA6;
            background-color: #00AAA6;
          }
          .leixingxing{
            color: #D9161C;
          }
          .radio:checked {
            color: #00AAA6;
          }

          .address {
            height: 64px;
            border-color: #EEEEEE;;
          }
        }
        .adressBOX {
          display: flex;
          align-items:flex-start;
          justify-content: center;
        }
        .aaa {
          p{
            .leftTxt{
              width: 100px;
              text-align: right;
            }
          }

        }
        .first {
          margin-top: 20px;
          display: flex;
          justify-content: start;
          span{
            //margin-left: 110px;


          }
        }

        .left {
          padding-left: 28px;
        }
      }
      .TWOPart {

        p {
          margin-bottom: 16px;
          display: flex;
          justify-content: center;
          align-items: center;

          input {
            width: 300px;
            height: 32px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #EEEEEE;
            padding-left: 16px;
          }

          .radio {
            width: 16px;
            height: 16px;
            border: 1px solid #00AAA6;
            background-color: #00AAA6;
          }
          .leixingxing{
            color: #D9161C;
          }
          .radio:checked {
            color: #00AAA6;
          }

          .address {
            height: 64px;
            border-color: #EEEEEE;;
          }
        }
        .adressBOX {
          display: flex;
          align-items:flex-start;
          justify-content: center;
        }
        p{
          .leftTxt{
            width: 100px;
            text-align: right;
          }
        }
        .aaa {
          p{
            .leftTxt{
              width: 100px;
              text-align: right;
            }
          }
        }
        .first {
          margin-top: 20px;
          display: flex;
          justify-content: start;
          span{
            //margin-left: 110px;

          }
        }

        .left {
          padding-left: 28px;
        }
      }
      .threePart {
        p {

          .leftTxt{
            width: 100px;
            text-align: right;
          }
          margin-bottom: 16px;
          display: flex;
          justify-content: center;
          align-items: center;

          input {
            width: 300px;
            height: 32px;
            background: #FFFFFF;
            border-radius: 4px;
            border: 1px solid #EEEEEE;
            padding-left: 16px;
          }

          .radio {
            width: 16px;
            height: 16px;
            border: 1px solid #00AAA6;
          }
          //span {
          //  color: #D9161C;
          //}
          .radio:checked {
            color: #00AAA6;
          }

          .address {
            height: 64px;
            border-color: #EEEEEE;;
          }
        }
        .adressBOX {
          display: flex;
          align-items:flex-start;
          justify-content: center;
        }
        .first{
          margin-top: 20px;
        }

        .left {
          padding-left: 28px;
        }
        .leixingxing{
          color: #D9161C;
        }
      }
    }
    .ant-modal-footer{
      text-align: center;
      border-top:0px solid #fff;
      padding:0px 0 30px;
      .ant-btn{
        width: 120px;
        height:40px;
        border-radius: 4px;
        font-size: 16px;
        color:#777;
      }
      .ant-btn-primary{
        color: #fff;
        background:#00AAA6;
        margin-left:24px;
      }
    }
  }
}
</style>
